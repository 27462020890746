import axios from 'axios';
import * as qs from 'querystring';
import { urlService } from 'app/configs';
import { AppToaster } from 'app/Components/toaster/Toaster';
import { Intent } from '@blueprintjs/core';
import { removeChatbotScript } from '../injectChatbot';
const CancelToken = axios.CancelToken;
let cancelList = [];
const instance = axios.create({
  baseURL: urlService,
});
// Comments không gán token cho api
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});
// Add a request interceptor
instance.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  console.log(error);
  if(error.response.status === 401 && window.location.href.indexOf('login') === -1 && window.location.href.indexOf('logout') === -1){
    AppToaster.clear();
    removeChatbotScript();
    AppToaster.show({
      message:"Phiên nào việc của bạn đã hết hạn, vui lòng đăng nhập lại",
      intent: Intent.WARNING,
      timeout:3000
    });
    window.location.href = '/logout';
  }
  return Promise.reject(error);
});

export const convertObjectToParams = (obj) => {
  var str = '';
  for (var key in obj) {
    if (str !== '') {
      str += '&';
    }
    str += key + '=' + encodeURIComponent(obj[key]);
  }
  return str;
};

export const _GET = (api, obj) => {
  let URL = obj ? api + '?' + convertObjectToParams(obj) : api;
  return instance.get(URL);
};
// export const _GET = (api, obj) => {
//   const username = localStorage.getItem('UserName');
//   let URL = obj ? api + `?username=${username}&` + convertObjectToParams(obj) : api;
//   return instance.get(URL);
// };
export const _POST = (api, formData, config = {}) => {
  return instance.post(api, formData, config);
};
export const _PUT = (api, obj) => {
  return instance.put(api, qs.stringify(obj));
};
export const _DELTE = (api, obj) => {
  return instance.delete(api, qs.stringify(obj));
};

export const _REQUEST_CANCEL = () => {
  cancelList.forEach((request) => {
    console.log(request);
  });
  cancelList = [];
};
