import React, { useState } from 'react';
import { Collapse } from '@blueprintjs/core';
import { Icon } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { updateRouterLevel } from '../../_redux/baoCaoTongQuanAction';
import SubMenuMobileBasic from '../SubMenuMobileBasic';
import { useHistory, Link } from 'react-router-dom';
import { MenuConfig } from './MenuTNConfigs';
import MenuItemCollapse from 'app/Components/menu-item-collapse/MenuItemCollapse';

export const MenuTN = ({ updaterouter, setMenuOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const collapseButton = () => {
    setIsOpen(!isOpen);
  };
  const initialState = {
    menulevel: {
      menulv1: 'TTML',
      menulv2: null,
      menulv3: null,
      menulv4: null
    }
  };

  let history = useHistory();
  const baseUrl = '/vienthong/tongquan/hatangso';

  const renderContent = (menuitems = []) => {
    return menuitems.map((menu, index) => {
      if (menu.disable) {
        return <span>{menu.label}</span>;
      }
      return (
        <MenuItemCollapse
          name={menu.label}
          fnClick={() => {
            history.push(menu.link);
            updaterouter(menu.router);
            setMenuOpen(false);
          }}
        >
          {menu.childrens.length > 0
            ? renderContent(menu.childrens)
            : menuTypes(menu, index)}
        </MenuItemCollapse>
      );
    });
  };

  const menuTypes = (menu, index) => {
    switch (menu.type) {
      default:
        return SubMenuMobileBasic((router) => {
          history.push(baseUrl);
          updaterouter(router);
          setMenuOpen(false);
        })('tn' + index);
      case 'basicmien':
        return (
          <React.Fragment>
              <MenuItemCollapse
          name={"Mien Bac"}
          fnClick={() => {
            history.push(menu.link);
            updaterouter({
              menulv1: "TTML",
              menulv2: "Mien Bac",
              menulv3: null,
              menulv4: null
            });
          }}
          menuClass="main-menu-item-collapse"
        ></MenuItemCollapse>
        <MenuItemCollapse
          name={"Mien Trung"}
          fnClick={() => {
            history.push(menu.link);
            updaterouter({
              menulv1: "TTML",
              menulv2: "Mien Trung",
              menulv3: null,
              menulv4: null
            });
          }}
          menuClass="main-menu-item-collapse"
        ></MenuItemCollapse>
        <MenuItemCollapse
          name={"Mien Nam"}
          fnClick={() => {
            history.push(menu.link);
            updaterouter({
              menulv1: "TTML",
              menulv2: "Mien Nam",
              menulv3: null,
              menulv4: null
            });
          }}
          menuClass="main-menu-item-collapse"
        ></MenuItemCollapse>
          </React.Fragment>
        );
    }
  };
  return (
    <div className="sideMenu">
      <div className="side-menu-button" style={{ position: 'relative' }}>
        <i className="fa fa-chart-line"></i>
        <Link
          to={baseUrl}
          onClick={() => {
            updaterouter(initialState);
            setMenuOpen(false);
          }}
          style={{ padding: '0 2px' }}
        >
          Hạ tầng số
        </Link>
        <Icon
          style={{ position: 'absolute', right: '1%', top: '32%' }}
          icon={isOpen ? 'double-chevron-down' : 'double-chevron-up'}
          onClick={() => collapseButton()}
        />
      </div>
      <Collapse isOpen={isOpen} keepChildrenMounted>
        <div className="menu-collapse">
         {renderContent(MenuConfig)}
        </div>
      </Collapse>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  updaterouter: (api, object) => dispatch(updateRouterLevel(api, object))
});

export default connect(null, mapDispatchToProps)(MenuTN);
