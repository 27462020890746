export const ADD_TAB = 'ADD_TAB';
export const UPDATE_ACTIVE_TAB = 'UPDATE_ACTIVE_TAB';
export const REMOVE_TAB = 'REMOVE_TAB';
export const REMOVE_ALL_TAB = 'REMOVE_ALL_TAB';

export const updateActiveTab = (activeTab) => {
  return {
    type: UPDATE_ACTIVE_TAB,
    payload: { activeTab }
  };
};
export const addTab = (tab) => {
  return {
    type: ADD_TAB,
    payload: { tab }
  };
};
export const removeTab = (tab) => {
  return {
    type: REMOVE_TAB,
    payload: { tab }
  };
};
export const removeAllTab = () => {
  return {
    type: REMOVE_ALL_TAB,
    payload: null
  };
};
