import { put, takeLatest } from 'redux-saga/effects';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action'
};

export const actions = {
  login: (user) => ({ type: actionTypes.Login, payload: { user } }),

  logout: () => ({ type: actionTypes.Logout })
  // requestUser: (user) => ({
  //   type: actionTypes.UserRequested,
  //   payload: { user },
  // }),
  // fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const user = yield getUserByToken();
  //   yield put(actions.fulfillUser(user));
  // });
}
