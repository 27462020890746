/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Collapse } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { updateRouterLevel } from '../../_redux/baoCaoTongQuanAction';
import SubMenuMobileBasic from '../SubMenuMobileBasic';
import { useHistory, Link } from 'react-router-dom';
import MenuItemCollapse from 'app/Components/menu-item-collapse/MenuItemCollapse';
import { Classes, Icon } from '@blueprintjs/core';

const initialState = {
  menulevel: {
    menulv1: 'TTML',
    menulv2: null,
    menulv3: null,
    menulv4: null
  }
};

export const MenuLLTLMobile = ({ updaterouter, setMenuOpen }) => {
  const [isOpen, setIsOpen] = useState(true);
  const collapseButton = () => {
    setIsOpen(!isOpen);
  };
  let history = useHistory();
  const baseUrl = '/vienthong/tongquan/luuluongthongluong';

  return (
    <div className="sideMenu">
      <div className="side-menu-button" style={{ position: 'relative' }}>
        <i className="flaticon-statistics"></i>
        <Link
          to={baseUrl}
          onClick={() => {
            updaterouter(initialState);
            setMenuOpen(false);
          }}
          style={{ padding: '0 2px' }}
        >
          Lưu lượng và Thông lượng
        </Link>
        <Icon
          style={{ position: 'absolute', right: '1%', top: '32%' }}
          icon={isOpen ? 'double-chevron-down' : 'double-chevron-up'}
          onClick={() => collapseButton()}
        />
      </div>
      <Collapse isOpen={isOpen} keepChildrenMounted>
        <div className="menu-collapse">
          <MenuItemCollapse
            name="Lưu lượng và Thông lượng"
            fnClick={() => {
              history.push(baseUrl);
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          >
            {SubMenuMobileBasic((router) => {
              history.push(baseUrl);
              updaterouter(router);
              setMenuOpen(false);
            })('lltl')}
          </MenuItemCollapse>
          <MenuItemCollapse
            name="Lưu Lượng Thoại"
            fnClick={() => {
              history.push(baseUrl + '/luuluongthoai');
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          >
            {SubMenuMobileBasic((router) => {
              history.push(baseUrl + '/luuluongthoai');
              updaterouter(router);
              setMenuOpen(false);
            })('llt')}
          </MenuItemCollapse>
          <MenuItemCollapse
            name="Lưu Lượng Data"
            fnClick={() => {
              history.push(baseUrl + '/luuluongdata');
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          >
            {SubMenuMobileBasic((router) => {
              history.push(baseUrl + '/luuluongdata');
              updaterouter(router);
              setMenuOpen(false);
            })('lld')}
          </MenuItemCollapse>
          <MenuItemCollapse
            name="Lưu Lượng SMS"
            fnClick={() => {
              history.push(baseUrl + '/luuluongsms');
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          ></MenuItemCollapse>
          <MenuItemCollapse
            name="Lưu Lượng Internet"
            fnClick={() => {
              history.push(baseUrl + '/luuluonginternet');
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          ></MenuItemCollapse>
          <MenuItemCollapse
            name="Thông lượng"
            fnClick={() => {
              history.push(baseUrl + '/thongluong');
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          ></MenuItemCollapse>
        </div>
      </Collapse>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  updaterouter: (api, object) => dispatch(updateRouterLevel(api, object))
});

export default connect(null, mapDispatchToProps)(MenuLLTLMobile);
