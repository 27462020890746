import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-query';
import { _POST } from 'app/services/apiCrud';
import { shallowEqual, useSelector } from 'react-redux';
import { Collapse } from '@blueprintjs/core';
import { Classes, Icon, Menu, MenuItem } from '@blueprintjs/core';
import { toReportName } from 'app/helper/utilities';
import { updateActiveTab, addTab } from '../_redux/Action';
import MenuItemCollapse from 'app/Components/menu-item-collapse/MenuItemCollapse';
import { pathToUrl } from 'app/helper/filter';

export const AsideMenu = ({ item, addTab, updateTab, isMobile, setMenuOpen }) => {
  // const user = useSelector(({ auth }) => auth.user, shallowEqual).UserName;
  const [isOpen, setIsOpen] = useState(false);
  const collapseButton = () => {
    setIsOpen(!isOpen);
  };

  const renderMenu = (report, fatherReportName = null, level = 1) => {
    let nameReport = fatherReportName + '-' + report?.Id + level;
    if (report.Childrens) {
      return (
        <MenuItem
          key={nameReport + 'render'}
          onClick={() => {
            addTab(report);
            updateTab(report);
            // insertAuditLogs.mutateAsync(report);
          }}
          text={toReportName(report.Name)}
        >
          {report.Childrens.map((child) => renderMenu(child, nameReport, level + 1))}
        </MenuItem>
      );
    } else {
      return (
        <MenuItem
          key={nameReport}
          onClick={() => {
            addTab(report);
            updateTab(report);
            // insertAuditLogs.mutateAsync(report);
          }}
          text={toReportName(report.Name)}
        ></MenuItem>
      );
    }
  };
  const renderMenuMobile = (report, fatherReportName = null, level = 1) => {
    let nameReport = fatherReportName + '-' + toReportName(report?.Name);
    if (report.Childrens != null) {
      return (
        <MenuItemCollapse
          key={nameReport + 'render'}
          name={toReportName(report.Name)}
          menuClass="menu-item-lv1"
          padLef={`${14 + 10 * level}px`}
          fnClick={() => {
            addTab(report);
            updateTab(report);
            // insertAuditLogs.mutateAsync(report);
            setMenuOpen(false);
          }}
        >
          {report.Childrens.map((child) =>
            renderMenuMobile(child, nameReport, level + 1)
          )}
        </MenuItemCollapse>
      );
    } else {
      return (
        <MenuItemCollapse
          key={nameReport}
          fnClick={() => {
            addTab(report);
            updateTab(report);
            // insertAuditLogs.mutateAsync(report);
            setMenuOpen(false);
          }}
          name={toReportName(report.Name)}
          menuClass="menu-item-lv1"
          padLef={`${14 + 10 * level}px`}
        ></MenuItemCollapse>
      );
    }
  };
  return (
    <div className="sideMenu">
        <div
          className="side-menu-button"
          style={{
            position: 'relative'
          }}
        >
          <span
            className="hoverlink"
            onClick={() => {
              addTab(item);
              updateTab(item);
            }}
          >
            {' '}
            {toReportName(item.Name)}
          </span>
          {item.Childrens && (
            <Icon
              style={{
                position: 'absolute',
                right: '1%',
                top: '32%'
              }}
              onClick={() => collapseButton()}
              icon={isOpen ? 'double-chevron-down' : 'double-chevron-up'}
            />
          )}
        </div>
      {item.Childrens && (
        <Collapse isOpen={isOpen} keepChildrensMounted>
          {isMobile && (
            <React.Fragment>
              {item.Childrens.map((e) => renderMenuMobile(e,item.Name))}
            </React.Fragment>
          )}
          {!isMobile && (
            <Menu className={Classes.ELEVATION_1} style={{ zIndex: '99' }}>
              {item.Childrens.map((e) => renderMenu(e,item.Name))}
            </Menu>
          )}
        </Collapse>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addTab: (api, object) => dispatch(addTab(api, object)),
  updateTab: (api, object) => dispatch(updateActiveTab(api, object))
});

export default connect(null, mapDispatchToProps)(AsideMenu);
