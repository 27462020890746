import React from 'react';
import {
  MenuData,
  MenuDataThueBao,
  MenuDataTN5G,
  MenuDiemHTMTData
} from './MenuData';
import MenuItemCollapse from 'app/Components/menu-item-collapse/MenuItemCollapse';

const SubMenuMobileBasic = (updateRouterLevel, type = '') => (uniqueKey) => {
  // key để tạo unique key
  let MenuDataView;
  switch (type) {
    default:
      MenuDataView = MenuData;
      break;
    case 'ThueBao':
      MenuDataView = MenuDataThueBao;
      break;
    case 'TaiNguyen5G':
      MenuDataView = MenuDataTN5G;
      break;
    case 'DiemHTMT':
      MenuDataView = MenuDiemHTMTData;
      break;
  }

  return (
    <React.Fragment>
      {MenuDataView.map((menugrouplv1, indexgrouplv1) => (
        <MenuItemCollapse
          key={uniqueKey + 'grouplv1' + indexgrouplv1}
          name={Object.keys(menugrouplv1)}
          menuClass="main-menu-item-collapse"
        >
          <MenuItemCollapse
            name="Toàn mạng"
            menuClass="menu-item-lv1"
            fnClick={() => {
              updateRouterLevel({
                menulv1: Object.keys(menugrouplv1),
                menulv2: null,
                menulv3: null,
                menulv4: null
              });
            }}
          ></MenuItemCollapse>
          {menugrouplv1[Object.keys(menugrouplv1)].length > 0 &&
            menugrouplv1[Object.keys(menugrouplv1)].map(
              (menugrouplv2, indexgrouplv2) => (
                <MenuItemCollapse
                  key={uniqueKey + 'grouplv2' + indexgrouplv2}
                  menuClass="menu-item-lv1"
                  name={Object.keys(menugrouplv2)}
                  fnClick={() => {
                    updateRouterLevel({
                      menulv1: Object.keys(menugrouplv1),
                      menulv2: Object.keys(menugrouplv2),
                      menulv3: null,
                      menulv4: null
                    });
                  }}
                >
                  {menugrouplv2[Object.keys(menugrouplv2)].map(
                    (menugrouplv3, indexgrouplv3) => (
                      <MenuItemCollapse
                        key={uniqueKey + 'grouplv3' + indexgrouplv3}
                        menuClass="menu-item-lv2"
                        name={menugrouplv3}
                        fnClick={() => {
                          updateRouterLevel({
                            menulv1: Object.keys(menugrouplv1),
                            menulv2: Object.keys(menugrouplv2),
                            menulv3: menugrouplv3,
                            menulv4: null
                          });
                        }}
                      ></MenuItemCollapse>
                    )
                  )}
                </MenuItemCollapse>
              )
            )}
        </MenuItemCollapse>
      ))}
    </React.Fragment>
  );
};
export default SubMenuMobileBasic;
