import { UPDATE_DATETIME_PICKER } from './dateTimePickerAction';
var date = new Date();

// subtract a day
date.setDate(date.getDate() - 1);

const initialState = {
  datetime: date
};

const updateDatetimePicker = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATETIME_PICKER:
      return {
        ...state,
        datetime: action.payload.datetime
      };
    default:
      return state;
  }
};

export default updateDatetimePicker;
