const baseUrl = '/vienthong/tongquan/hatangso';
const initialState = {
    menulevel: {
      menulv1: 'TTML',
      menulv2: null,
      menulv3: null,
      menulv4: null
    }
  };
export const MenuConfig = [
  {
    id: 'hatangso',
    label: 'Hạ tấng số',
    type: 'basic',
    link: baseUrl,
    router: initialState,
    childrens:[],
},
{
  id: 'ran',
  label: 'RAN',
  type: 'basic',
  link: baseUrl + '/hatangso',
  router:initialState,
  childrens:[
    {
      id: 'tram',
      label: 'Trạm',
      type: 'basic',
      link: baseUrl + '/diemphatsong',
      router:initialState,
      childrens:[],
      stt:0
    },
    {
      id: 'tram2g',
      label: 'Trạm 2G',
      type: 'basic',
      link: baseUrl + '/tn_ran2g',
      router:initialState,
      childrens:[],
      stt:1
    },
    {
      id: 'tram3g',
      label: 'Trạm 3G',
      type: 'basic',
      link: baseUrl + '/tn_ran3g',
      router:initialState,
      childrens:[],
      stt:1
    },
    {
      id: 'tram4g',
      label: 'Trạm 4G',
      type: 'basic',
      link: baseUrl + '/tn_ran4g',
      router:initialState,
      childrens:[],
      stt:1
    },
    {
      id: 'tram5g',
      label: 'Trạm 5G',
      type: 'basic',
      link: baseUrl + '/tn_ran5g',
      router:initialState,
      childrens:[],
      stt:1
    }
  ],
},
{
  id: 'core',
  label: 'CORE',
  type: 'basicmien',
  link: baseUrl + '/tn_core',
  router:initialState,
  childrens:[
  ],
},
{
  id: 'cloud',
  label: 'Cloud',
  type: 'basicmien',
  link: baseUrl + '/tn_cloud',
  router:initialState,
  childrens:[
  ],
},
{
  id: 'truyendan',
  label: 'TRUYỀN DẪN (đang bổ sung)',
  type: 'basicmien',
  disable: true,
  childrens:[
  ],
},
];