import React from 'react';
import { Classes } from '@blueprintjs/core';

export default function LoadingMenu() {
  return (
    <React.Fragment>
      <p className={Classes.SKELETON}>
        <div className="sideMenu">
          <div
            className="side-menu-button"
            style={{
              position: 'relative'
            }}
          >
            <span className="hoverlink" style={{ padding: '0 2vh' }}></span>
          </div>
        </div>
      </p>
      <p className={Classes.SKELETON}>
        <div className="sideMenu">
          <div
            className="side-menu-button"
            style={{
              position: 'relative'
            }}
          >
            <span className="hoverlink" style={{ padding: '0 2vh' }}></span>
          </div>
        </div>
      </p>
      <p className={Classes.SKELETON}>
        <div className="sideMenu">
          <div
            className="side-menu-button"
            style={{
              position: 'relative'
            }}
          >
            <span className="hoverlink" style={{ padding: '0 2vh' }}></span>
          </div>
        </div>
      </p>
      <p className={Classes.SKELETON}>
        <div className="sideMenu">
          <div
            className="side-menu-button"
            style={{
              position: 'relative'
            }}
          >
            <span className="hoverlink" style={{ padding: '0 2vh' }}></span>
          </div>
        </div>
      </p>
      <p className={Classes.SKELETON}>
        <div className="sideMenu">
          <div
            className="side-menu-button"
            style={{
              position: 'relative'
            }}
          >
            <span className="hoverlink" style={{ padding: '0 2vh' }}></span>
          </div>
        </div>
      </p>
      <p className={Classes.SKELETON}>
        <div className="sideMenu">
          <div
            className="side-menu-button"
            style={{
              position: 'relative'
            }}
          >
            <span className="hoverlink" style={{ padding: '0 2vh' }}></span>
          </div>
        </div>
      </p>
      <p className={Classes.SKELETON}>
        <div className="sideMenu">
          <div
            className="side-menu-button"
            style={{
              position: 'relative'
            }}
          >
            <span className="hoverlink" style={{ padding: '0 2vh' }}></span>
          </div>
        </div>
      </p>
    </React.Fragment>
  );
}
