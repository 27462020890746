import React, { useState } from 'react';
import { Collapse } from '@blueprintjs/core';
import { Icon } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { updateRouterLevel } from '../../_redux/baoCaoTongQuanAction';
import SubMenuTruyenDanMobile from '../SubMenuTruyenDan/SubMenuTruyenDanMobile';
import SubMenuMobileBasic from '../SubMenuMobileBasic';
import { useHistory, Link } from 'react-router-dom';
import MenuItemCollapse from 'app/Components/menu-item-collapse/MenuItemCollapse';
import { MenuCLCore } from '../MenuData';

export const MenuCL = ({ updaterouter, setMenuOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const collapseButton = () => {
    setIsOpen(!isOpen);
  };
  const initialState = {
    menulevel: {
      menulv1: 'TTML',
      menulv2: null,
      menulv3: null,
      menulv4: null
    }
  };
  let history = useHistory();
  const baseUrl = '/vienthong/tongquan/chatluong';

  return (
    <div className="sideMenu">
      <div className="side-menu-button" style={{ position: 'relative' }}>
        <i className="fas fa-thermometer-quarter"></i>
        <Link
          to={baseUrl}
          onClick={() => {
            updaterouter(initialState);
            setMenuOpen(false);
          }}
          style={{ padding: '0 2px' }}
        >
          Chất lượng
        </Link>
        <Icon
          style={{ position: 'absolute', right: '1%', top: '32%' }}
          icon={isOpen ? 'double-chevron-down' : 'double-chevron-up'}
          onClick={() => collapseButton()}
        />
      </div>{' '}
      <Collapse isOpen={isOpen} keepChildrenMounted>
        <div className="menu-collapse">
          <MenuItemCollapse
            name="Điểm HTMT"
            fnClick={() => {
              history.push(baseUrl + '/diemhtmt');
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          >
            {SubMenuMobileBasic((router) => {
              history.push(baseUrl + '/diemhtmt');
              updaterouter(router);
              setMenuOpen(false);
            }, 'DiemHTMT')('clmran')}
          </MenuItemCollapse>

          <MenuItemCollapse
            name="Chất lượng mạng RAN"
            fnClick={() => {
              history.push(baseUrl + '/chatluongmangcore');
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          >
            {SubMenuMobileBasic((router) => {
              history.push(baseUrl + '/chatluongmangran');
              updaterouter(router);
              setMenuOpen(false);
            })('clmran')}
          </MenuItemCollapse>
          <MenuItemCollapse
            name="Chất lượng mạng CORE"
            fnClick={() => {
              history.push(baseUrl + '/chatluongmangcore');
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          >
            <MenuItemCollapse name="Miền" menuClass="main-menu-item-collapse">
              {MenuCLCore.map((menu, index) => (
                <MenuItemCollapse
                  key={'clmCore' + index}
                  fnClick={() => {
                    history.push(baseUrl + '/chatluongmangcore');
                    updaterouter({
                      menulv1: 'TTML',
                      menulv2: menu.name,
                      menulv3: null,
                      menulv4: null
                    });
                  }}
                  name={menu.name}
                ></MenuItemCollapse>
              ))}
            </MenuItemCollapse>
          </MenuItemCollapse>
          <MenuItemCollapse
            name="Chất lượng truyền dẫn"
            fnClick={() => {
              history.push(baseUrl + '/chatluongtruyendan');
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          >
            {MenuCLCore.map((menu, index) => (
              <MenuItemCollapse
                key={'clmCore' + index}
                fnClick={() => {
                  history.push(baseUrl + '/chatluongtruyendan');
                  updaterouter({
                    menulv1: 'TTML',
                    menulv2: menu.name,
                    menulv3: null,
                    menulv4: null
                  });
                }}
                name={menu.name}
              ></MenuItemCollapse>
            ))}
          </MenuItemCollapse>
          <MenuItemCollapse
            name="Chất lượng dịch vụ KQI"
            fnClick={() => {
              history.push(baseUrl + '/chatluongdichvukqi');
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          >
            {SubMenuMobileBasic((router) => {
              history.push(baseUrl + '/chatluongdichvukqi');
              updaterouter(router);
              setMenuOpen(false);
            })('clmkqi')}
          </MenuItemCollapse>
        </div>
      </Collapse>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  updaterouter: (api, object) => dispatch(updateRouterLevel(api, object))
});

export default connect(null, mapDispatchToProps)(MenuCL);
