/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from 'react';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { Logout, AuthPage } from './modules/Auth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import { setCookie } from 'app/helper/utilities';
import { connect } from 'react-redux';
import { updateDatetimePicker } from '../app/Components/datetimepicker/_redux/dateTimePickerAction';
import { _GET } from './services/apiCrud';
import { isEmpty } from 'lodash';
import { Intent } from '@blueprintjs/core';
import moment from 'moment';
import { AppToaster } from 'app/Components/toaster/Toaster';
import { injectChatbotScript, removeChatbotScript } from './injectChatbot';

function Routes({ updateDatetimePicker }) {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user
    }),
    shallowEqual
  );
  const history = useHistory();


  useEffect(() => {
    if (isAuthorized) {
      injectChatbotScript();
      const isExpired = moment(new Date(isAuthorized['.expires'])).isBefore(
        moment()._d
      );
      console.log('expiresDate:', new Date(isAuthorized['.expires']));
      console.log('now:', moment()._d);
      const username = localStorage.getItem('UserName');
      fetchHardCode();
      if (isExpired) {
        removeChatbotScript();
        history.push('/logout');
        AppToaster.show({
          message: 'Phiên đăng nhập hết hạn!',
          intent: Intent.WARNING,
          timeout: 1000
        });
        console.log('logout check' + new Date());
      }
      if (!username) {
        removeChatbotScript();
        AppToaster.clear();
        AppToaster.show({
          message: 'Bạn không có quyền truy cập!',
          intent: Intent.WARNING,
          timeout: 3000
        });
        history.push('/logout');
        console.log('logout check' + new Date());
      }
    }
    async function fetchHardCode() {
      let response = await _GET('hard-code-day');
      var date = new Date();
      date.setDate(date.getDate() - 1);

      // updateDatetimePicker(new Date("2021","05","06"));
      if (!isEmpty(response?.data)) {
        const dateArr = response?.data?.Result?.Date.split('/');
        const toDay = new Date(dateArr[2], +dateArr[1] - 1, dateArr[0]);
        if (
          response?.data?.Result?.Date &&
          toDay.toDateString() !== date.toDateString()
        ) {
          updateDatetimePicker(toDay);
        }
      }
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (isAuthorized) {
      if (
        isAuthorized?.listMenusAlow?.find(
          (item) => item.Url === history.location.pathname
        ) !== undefined
      ) {
        AppToaster.clear();
        AppToaster.show({
          message: 'Bạn không có quyền truy cập vào trang web này!',
          intent: Intent.DANGER,
          timeout: 4000
        });
        history.push('/logout');
        console.log('logout check' + new Date());
      }
    }
  }, [history.location.pathname]);

  return (
    <Switch>
      {/* <Route path="/mobifone.web/:path" render={({ match }) => {
        console.log(123,match)
        return  (
          <Redirect to={`/${match.params.path}`} />
      )
      }} /> */}
      {!(isAuthorized != null) ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}
      <Route path="/mobifone.web/:path">
        {({ location }) => 
        {
          console.log(location);
          const correctPath = location.pathname.replace('/mobifone.web', '') + location.search;
          window.location.href = correctPath;
          // return <Redirect to={correctPath} />
        }
        }
      </Route>
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
const mapDispatchToProps = (dispatch) => ({
  updateDatetimePicker: (api, object) => dispatch(updateDatetimePicker(api, object))
});

export default connect(null, mapDispatchToProps)(Routes);
