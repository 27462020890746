import React from 'react';
import MenuLLTLMobile from './MenuLLTL/MenuLLTLMobile';
import MenuTBMobile from './MenuTB/MenuTBMobile';
import MenuCLMobile from './MenuCL/MenuCLMobile';
import MenuTNMobile from './MenuTN/MenuTNMobile';
import MenuTHPMobile from './MenuTHP/MenuTHPMobile';

export const MobileBCTQ = ({ setMenuOpen }) => {
  return (
    <React.Fragment>
      <MenuLLTLMobile setMenuOpen={setMenuOpen} />
      <MenuTBMobile setMenuOpen={setMenuOpen} />
      <MenuCLMobile setMenuOpen={setMenuOpen} />
      <MenuTNMobile setMenuOpen={setMenuOpen} />
      <MenuTHPMobile setMenuOpen={setMenuOpen} />
    </React.Fragment>
  );
};

export default MobileBCTQ;
