/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { isMobile } from 'react-device-detect';
import { Classes, Popover2, Popover2InteractionKind } from '@blueprintjs/popover2';
import { Menu, MenuItem } from '@blueprintjs/core';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import { MenuList } from 'app/configs';
import { _GET, _POST, convertObjectToParams } from 'app/services/apiCrud';

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? 'menu-item-active' : '';
  };
  const history = useHistory();
  const RedirectUrl = (url) => {
    return history.push(url);
  };
  const getQuyenDoanhThuChiPhi = useQuery(['Getquyen'], () =>
  _GET('quyen-doanh-thu-chi-phi')
);
let quyen = getQuyenDoanhThuChiPhi.data?.data;

  const renderItem = ({ name, url, children = [] }) => {
    if (name === 'Doanh Thu Chi Phí' && quyen === false) {
      return null; // Không render MenuItem nếu name là 'Doanh thu Chi Phí' và quyen là false
    }
    if (children.length > 0) {
      return (
        <MenuItem
          active={getMenuItemActive(url)}
          text={name}
          key={name}
          onClick={() => RedirectUrl(url)}
        >
          {children.map((item) => renderItem(item))}
        </MenuItem>
      );
    }
    return (
      <MenuItem
        active={getMenuItemActive(url)}
        text={name}
        key={name}
        onClick={() => RedirectUrl(url)}
      />
    );
  };

  
  const renderMenu = (items = []) => {
    return <Menu key="menu">{items.map((item) => renderItem(item))}</Menu>;
  };


  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile `}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav`}>
        {/*begin::1 Level*/}
        {MenuList.map((menulv1) => {
          if (menulv1.hasOwnProperty('children')) {
            return (
              <li
                key={menulv1.name}
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  '/' + menulv1.url
                )}`}
              >
                <Popover2
                  interactionKind={Popover2InteractionKind.CLICK
                  }
                  className="menu-link"
                  popoverClassName={Classes.POPOVER2}
                  placement="bottom"
                  enforceFocus={false}
                  content={renderMenu(menulv1.children)}
                >
                  <a className={`menu-link`}>
                    <span className="menu-text">{menulv1.name}</span>
                  </a>
                </Popover2>
              </li>
            );
          } else {
            return (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  '/' + menulv1.url
                )}`}
              >
                <NavLink className="menu-link" to={'/' + menulv1.url}>
                  <span className="menu-text">{menulv1.name}</span>
                </NavLink>
              </li>
            );
          }
        })}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            '/dashboardchitiet'
          )}`}
        >
          <NavLink className="menu-link" to={'/dashboardchitiet'}>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            '/dashboardquantri'
          )}`}
        >
          <NavLink className="menu-link" to={'/dashboardquantri'}>
            <span className="menu-text">Quản trị</span>
          </NavLink>
        </li>
        <li className={`menu-item menu-item-rel ${getMenuItemActive('/trogiup')}`}>
          <NavLink className="menu-link" to={'/trogiup'}>
            <span className="menu-text">Trợ Giúp</span>
          </NavLink>
        </li>
        <li className={`menu-item menu-item-rel `}>
            <a className="menu-link" href="https://smartw.mobifone.vn/smartw/loginDefault.htm" target="_blank" rel="noopener noreferrer">
              <span className="menu-text">sMartW</span>
            </a>
         </li>
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
