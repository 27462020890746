import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import updateDatetimeHeader from 'app/Components/datetimepicker/_redux/dateTimePickerReducers';
import updateRouterLevel from 'app/pages/VienThong/BaoCaoTongQuan/_redux/baoCaoTongQuanReducer';
import updateTabs from 'app/pages/VienThong/BaoCaoChiTiet/_redux/baoCaoChiTietReducer';
import updateTabChuyenSau from 'app/pages/VienThong/ChuyenSau/_redux/baoCaoChiTietReducer';
import * as authAction from '../app/modules/Auth/_redux/authAction';
import * as auth from '../app/modules/Auth/_redux/authReducer';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  updateDatetimeHeader,
  updateRouterLevel,
  updateTabs,
  updateTabChuyenSau
});

export function* rootSaga() {
  yield all([authAction.saga()]);
}
