import _ from 'lodash';

export const HandlerDataMenu = (menudata) => {
  menudata.unshift({
    Id: '1b588945-b58e-455e-8d74-4f8ed188dba5',
    ParentId: '9cd91155-3110-450f-b074-fcdf72d4ab42',
    Name: 'noc-sopirs',
    Description: null,
    TypeName: 'Folder',
    Path: '/noc-sopirs',
    Level: 1,
    Checked: false,
    ParentCount: 0,
    IsParent: true,
    Children: null,
    ReportGroups: null
  });
  menudata.unshift({
    Id: '0e609f1e-abee-4d7f-aa9d-511ed36b14de',
    ParentId: '1b588945-b58e-455e-8d74-4f8ed188dba5',
    Name: '12.DASHBOARD',
    Description: null,
    TypeName: 'Folder',
    Path: '/noc-sopirs/12.DASHBOARD',
    Level: 1,
    Checked: false,
    ParentCount: 0,
    IsParent: true,
    Children: null,
    ReportGroups: null
  });
  let result = [];
  const findMenu = (items, id, arr) => {
    if (!items) return;
    items.forEach((item) => {
      if (item.Id === id) {
        item.Children = arr;
      } else if (item.Children) {
        findMenu(item.Children, id, arr);
      }
    });
  };

  const getMenu = (id) => {
    let childrens = [];
    menudata.forEach((report) => {
      if (report.ParentId === id) {
        childrens.push(report);
      }
    });
    findMenu(result, id, childrens);
  };

  menudata.forEach((report) => {
    if (
      report.ParentId === '1b588945-b58e-455e-8d74-4f8ed188dba5' &&
      result.filter((menu) => menu.Id === report.Id).length === 0
    ) {
      result.push(report);
    }
    getMenu(report.Id);
  });
  return result;
};

export default HandlerDataMenu;
