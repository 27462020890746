import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-query';
import { _POST } from 'app/services/apiCrud';
import { shallowEqual, useSelector } from 'react-redux';
import { Collapse } from '@blueprintjs/core';
import { Classes, Icon, Menu, MenuItem } from '@blueprintjs/core';
import { toReportName } from 'app/helper/utilities';
import {
  updateActiveTab,
  addTab
} from '../../VienThong/BaoCaoChiTiet/_redux/BaoCaoChiTietAction';
import MenuItemCollapse from 'app/Components/menu-item-collapse/MenuItemCollapse';
import { pathToUrl } from 'app/helper/filter';

export const AsideMenu = ({ item, addTab, updateTab, isMobile, setMenuOpen }) => {
  const user = useSelector(({ auth }) => auth.user, shallowEqual).UserName;
  const [isOpen, setIsOpen] = useState(true);
  const collapseButton = () => {
    setIsOpen(!isOpen);
  };

  const insertAuditLogs = useMutation(
    (event) => {
      var baocao =  "Dashboard";
      return _POST('add-audit-logs?report=' + baocao + '&&details=' + event?.Description);
    },
    {
      onSuccess: async (res) => {
      },
      onError: (error, variables, context) => {
      }
    }
  );
  const renderMenu = (report, fatherReportName = null, level = 1) => {
    let nameReport = fatherReportName + '-' + toReportName(report?.Name);
    if (report.IsParent) {
      return (
        <MenuItem key={nameReport + 'render'} text={toReportName(report.Name)}>
          {report.Children.map((child) => renderMenu(child, nameReport, level + 1))}
        </MenuItem>
      );
    } else {
      return (
        <MenuItem
          key={nameReport}
          onClick={() => {
            addTab(report);
            updateTab(report);
            insertAuditLogs.mutateAsync(report);
          }}
          text={toReportName(report.Name)}
        ></MenuItem>
      );
    }
  };
  const renderMenuMobile = (report, fatherReportName = null, level = 1) => {
    let nameReport = fatherReportName + '-' + toReportName(report?.Name);
    if (report.IsParent) {
      return (
        <MenuItemCollapse
          key={nameReport + 'render'}
          name={toReportName(report.Name)}
          menuClass="menu-item-lv1"
          padLef={`${14 + 10 * level}px`}
        >
          {report.Children.map((child) =>
            renderMenuMobile(child, nameReport, level + 1)
          )}
        </MenuItemCollapse>
      );
    } else {
      return (
        <MenuItemCollapse
          key={nameReport}
          fnClick={() => {
            addTab(report);
            updateTab(report);
            insertAuditLogs.mutateAsync(report);
            setMenuOpen(false);
          }}
          name={toReportName(report.Name)}
          menuClass="menu-item-lv1"
          padLef={`${14 + 10 * level}px`}
        ></MenuItemCollapse>
      );
    }
  };
  return (
    <div className="sideMenu">
      {item.TypeName === 'Custom' && (
        <div
          className="side-menu-button"
          style={{
            position: 'relative'
          }}
          onClick={() => {
            collapseButton();
            addTab(item);
            updateTab(item);
            insertAuditLogs.mutateAsync(item);
            setMenuOpen(false);
          }}
        >
          <span
            className="hoverlink"
            onClick={() => {}}
            style={{ padding: '0 2px' }}
          >
            {toReportName(item.Name)}
          </span>
        </div>
      )}
      {item.TypeName !== 'Custom' && (
        <div
          className="side-menu-button"
          style={{
            position: 'relative'
          }}
          onClick={() => collapseButton()}
        >
          <span> {toReportName(item.Name)}</span>
          {item.Children.length !== 0 && (
            <Icon
              style={{
                position: 'absolute',
                right: '1%',
                top: '32%'
              }}
              icon={isOpen ? 'double-chevron-down' : 'double-chevron-up'}
            />
          )}
        </div>
      )}
      {item.Children.length !== 0 && (
        <Collapse isOpen={isOpen} keepChildrenMounted>
          {isMobile && (
            <React.Fragment>
              {item.Children.map((e) => renderMenuMobile(e))}
            </React.Fragment>
          )}
          {!isMobile && (
            <Menu className={Classes.ELEVATION_1} style={{ zIndex: '99' }}>
              {item.Children.map((e) => renderMenu(e))}
            </Menu>
          )}
        </Collapse>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addTab: (api, object) => dispatch(addTab(api, object)),
  updateTab: (api, object) => dispatch(updateActiveTab(api, object))
});

export default connect(null, mapDispatchToProps)(AsideMenu);
