/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Children, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Classes, Icon, Menu, MenuItem } from '@blueprintjs/core';
import { Collapse } from '@blueprintjs/core';

const MenuItemCollapse = ({
  name,
  fnClick,
  menuClass = 'menu-collapse-wrapper',
  padLef = '',
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const collapseButton = () => {
    setIsOpen(!isOpen);
  };
  const nameChange = (name) => {
    if (typeof name == 'string') {
      switch (name) {
        default:
          return name;
        case 'TTML':
          return 'Trung Tâm';
        case 'CTKD':
          return 'CTKD';
        case 'VDL':
          return 'Vùng Địa Lý';
      }
    }
    return name;
  };
  return (
    <React.Fragment>
      <span className={menuClass} style={{ paddingLeft: padLef }}>
        {fnClick && (
          <a href="#" className="menu-item-collapse" onClick={fnClick}>
            {name}
          </a>
        )}
        {!fnClick && <span className="menu-item-collapse">{nameChange(name)}</span>}
        {children && (
          <span className="menu-icon-collapse">
            <Icon
              icon={isOpen ? 'double-chevron-down' : 'double-chevron-up'}
              onClick={() => collapseButton()}
            />
          </span>
        )}
      </span>
      {children && (
        <Collapse isOpen={isOpen} keepChildrenMounted>
          {children}
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default connect()(MenuItemCollapse);
