import { ADD_TAB } from './BaoCaoChiTietAction';
import { UPDATE_ACTIVE_TAB } from './BaoCaoChiTietAction';
import { REMOVE_TAB } from './BaoCaoChiTietAction';
import { REMOVE_ALL_TAB } from './BaoCaoChiTietAction';

const initialState = {
  activeTab: {
    id: null,
    title: null,
    url: null,
    router: null
  },
  tabs: []
};

const updateTabs = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TAB:
      if (!state.tabs.some((tab) => tab.Id === action.payload.tab.Id)) {
        return {
          ...state.tabs,
          tabs: [...state.tabs, action.payload.tab]
        };
      } else {
        return state;
      }
    case UPDATE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload.activeTab
      };
    case REMOVE_TAB:
      if (state.activeTab.Id === action.payload.tab.Id) {
        const indexActiveTab = state.tabs.findIndex(
          (tab) => tab.Id === action.payload.tab.Id
        );
        let activeTab = {};
        if (indexActiveTab === 0 && state.tabs.length !== 1) {
          activeTab = state.tabs[1];
        } else if (state.tabs.length !== 1) {
          activeTab = state.tabs[+indexActiveTab - 1];
        }
        return {
          ...state,
          activeTab: activeTab,
          tabs: state.tabs.filter((tab) => tab.Id !== action.payload.tab.Id)
        };
      }
      return {
        ...state.tabs,
        activeTab: state.activeTab,
        tabs: state.tabs.filter((tab) => tab.Id !== action.payload.tab.Id)
      };
    case REMOVE_ALL_TAB:
      return {
        ...state,
        activeTab: initialState.activeTab,
        tabs: []
      };
    default:
      return state;
  }
};

export default updateTabs;
