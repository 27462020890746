import React, { useState } from 'react';
import { NonIdealState } from '@blueprintjs/core';

const Error = ({
  icon = 'unresolve',
  title = 'Đường truyền đang bận',
  description = 'Vui lòng thử lại sau!'
}) => {
  return (
    <div>
      <NonIdealState icon={icon} title={title} description={description} />
    </div>
  );
};

export default Error;
