import React, { useMemo, useState } from 'react';
import { Drawer, Button } from '@blueprintjs/core';
import SVG from 'react-inlinesvg';
import AsideMenuMobile from 'app/pages/AsideMenuMobile';

export const MenuDrawer = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <button
        className="btn p-0 burger-icon burger-icon-right ml-4"
        onClick={() => setMenuOpen(true)}
      >
        <span />
      </button>
      <AsideMenuMobile isOpen={menuOpen} setMenuOpen={setMenuOpen} />
    </>
  );
};

export default MenuDrawer;
