import React, { useState } from 'react';
import classNames from 'classnames';
import {
  Button,
  Classes,
  Code,
  H3,
  H5,
  Intent,
  Dialog,
  Icon
} from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { osName, osVersion, deviceType } from 'react-device-detect';

export const OverlayBlocking = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Dialog
      title=""
      style={{ width: '85vw' }}
      isOpen={isOpen}
      canOutsideClickClose={false}
      usePortal
      lazy
      onClose={() => setIsOpen(false)}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          <strong>
            Bạn đang truy cập từ thiết bị {deviceType} hệ điều hành {osName} version{' '}
            {osVersion}
          </strong>
        </p>
        <p>
          <strong>
            Hiện giao diện cho thiết bị của bạn đang liên tục được tối ưu.
          </strong>
        </p>
        <p>
          <strong>
            Các loại thiết bị đã được tối ưu giao diện: DESKTOP, LAPTOP, TABLET
            (IPAD, GALAXYTAB)...
          </strong>
        </p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent={Intent.PRIMARY} onClick={() => setIsOpen(false)}>
            Đồng ý
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default OverlayBlocking;
