import React from 'react';
import { connect } from 'react-redux';
import { toReportName } from 'app/helper/utilities';
import { useHistory } from 'react-router-dom';

const AsideMenuURL = ({ menulist, fnClick }) => {
  let history = useHistory();

  return (
    <>
      {menulist.map((menu, index) => (
        <div key={'menuurl' + index} className="sideMenu">
          <div
            className="side-menu-button"
            style={{
              position: 'relative'
            }}
          >
            {fnClick && (
              <span
                className="hoverlink"
                onClick={() => {
                  history.push(menu.url);
                  fnClick();
                }}
                style={{ padding: '0 2px' }}
              >
                {toReportName(menu.Name)}
              </span>
            )}
            {!fnClick && (
              <span
                className="hoverlink"
                onClick={() => {
                  history.push(menu.url);
                }}
                style={{ padding: '0 2px' }}
              >
                {toReportName(menu.Name)}
              </span>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default connect()(AsideMenuURL);
