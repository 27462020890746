import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';

const VienThongPage = lazy(() => import('./pages/VienThong/VienThongPage'));
const UserProfilePage = lazy(() => import('./modules/UserProfile/UserProfilePage'));
const BaoCaoNgayCNTTPage = lazy(() =>
  import('./pages/CNTT/CNTTPage.jsx')
);
const DashboardChiTietPage = lazy(() =>
  import('./pages/DashboardChiTiet/DashboardChiTietPage.jsx')
);
const DashboardQuanTriChiTietPage = lazy(() =>
  import('./pages/DashboardQuanTri/DashboardQuanTriChiTietPage.jsx')
);
const TroGiup = lazy(() => import('./pages/TroGiup/TroGiupsPage'));
const QLCL = lazy(()=> import ('./pages/QLCL/QLCL'));

export default function BasePage(props) {
  // useEffect(() => {
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/vienthong" />
          }
          <ContentRoute path="/vienthong" component={VienThongPage} />
          <ContentRoute path="/qlcl" component={QLCL} />
          <ContentRoute path="/cntt" component={BaoCaoNgayCNTTPage} />
          <ContentRoute path="/dashboardchitiet" component={DashboardChiTietPage} />
          <ContentRoute path="/dashboardquantri" component={DashboardQuanTriChiTietPage} />
          <ContentRoute path="/trogiup" component={TroGiup} />
          <ContentRoute path="/user-profile" component={UserProfilePage} />
          {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
          {/* <Redirect to="error/error-v1" /> */}
        </Switch>
      </Suspense>
    </>
  );
}
