/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from '../app/Routes';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppToaster } from 'app/Components/toaster/Toaster';
import { getCookie } from 'app/helper/utilities';
import {  Intent, Position } from '@blueprintjs/core';
import { Detector } from "react-detect-offline";
export default function App({ store, persistor, basename }) {
  const queryClient = new QueryClient();
  queryClient.setDefaultOptions({
    queries: {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      staleTime: 0,
    },
  });
  let checked = getCookie('checkedForOverlay');
  if (!checked || checked === 'false') {
    document.cookie = 'checkedForOverlay=false;';
  }
  //Chặn người dùng truy cập từ mobile
  // if(isMobileOnly){
  //   return <NoSupportDevicePage/>
  // }

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              {/* Render routes with provided `Layout`. */}
              <QueryClientProvider client={queryClient} contextSharing={true}>
              <Detector
                      onChange={( online ) => 
                        {
                          console.log(online);
                          AppToaster.clear();
                          AppToaster.show({
                            position: Position.TOP,
                            message: online ? "Kết nối Internet thành công" : "Mất kết nối Internet",
                            timeout: 3000,
                            intent: online ? Intent.SUCCESS : Intent.DANGER
                          });
                        }
                      }
                      polling={false}
                      render={({ online }) => (
                        <div className={online ? "d-none" : "offline-messages"}>
                          Bạn hiện tại không có kết nối tới Internet
                        </div>
                      )}
                    />
                <Routes />
              </QueryClientProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
