import React, { useState } from 'react';
import { Collapse } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { updateRouterLevel } from '../../_redux/baoCaoTongQuanAction';
import SubMenuMobileBasic from '../SubMenuMobileBasic';
import { useHistory, Link } from 'react-router-dom';
import MenuItemCollapse from 'app/Components/menu-item-collapse/MenuItemCollapse';
import { Icon } from '@blueprintjs/core';

export const MenuTHP = ({ updaterouter, setMenuOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const collapseButton = () => {
    setIsOpen(!isOpen);
  };
  let history = useHistory();
  const baseUrl = '/vienthong/tongquan/throughtput';

  const initialState = {
    menulevel: {
      menulv1: 'TTML',
      menulv2: null,
      menulv3: null,
      menulv4: null
    }
  };

  return (
    <div className="sideMenu">
      <div className="side-menu-button" style={{ position: 'relative' }}>
        <i className="flaticon2-phone"></i>
        <Link
          to={baseUrl}
          onClick={() => {
            updaterouter(initialState);
            setMenuOpen(false);
          }}
          style={{ padding: '0 2px' }}
        >
          Throughtput
        </Link>
        <Icon
          style={{ position: 'absolute', right: '1%', top: '32%' }}
          icon={isOpen ? 'double-chevron-down' : 'double-chevron-up'}
          onClick={() => collapseButton()}
        />
      </div>
      <Collapse isOpen={isOpen} keepChildrenMounted>
        <div className="menu-collapse">
          <MenuItemCollapse
            name="Throughtput"
            fnClick={() => {
              history.push(baseUrl + '/throughtput');
              updaterouter(initialState);
              setMenuOpen(false);
            }}
          >
            {SubMenuMobileBasic((router) => {
              history.push(baseUrl + '/throughtput');
              updaterouter(router);
              setMenuOpen(false);
            }, 'Throughtput')('lld')}
          </MenuItemCollapse>
        </div>
      </Collapse>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  updaterouter: (api, object) => dispatch(updateRouterLevel(api, object))
});

export default connect(null, mapDispatchToProps)(MenuTHP);
