export const MenuData = [
  {
    TTML: [
      {
        'Mien Bac': [
          'DVT Ha Noi 1',
          'DVT Ha Noi 2',
          'DVT Hai Phong',
          'DVT Nam Dinh',
          'DVT Nghe An',
          'DVT Thai Nguyen',
          'DVT Vinh Phuc'
        ]
      },
      {
        'Mien Trung': ['DVT Binh Dinh', 'DVT Da Nang', 'DVT Daklak']
      },
      {
        'Mien Nam': [
          'DVT Binh Duong',
          'DVT Can Tho',
          'DVT Dong',
          'DVT Dong Nai',
          'DVT Tay',
          'DVT Tien Giang'
        ]
      }
    ]
  },
  {
    CTKD: [
      {
        'CTKD 1': ['Ha Noi 1', 'Ha Noi 2', 'Ha Noi 3']
      },
      {
        'CTKD 2': ['Ho Chi Minh 1', 'Ho Chi Minh 2', 'Thu Duc']
      },
      {
        'CTKD 3': [
          'Binh Dinh',
          'Da Nang 1',
          'Da Nang 2',
          'Quang Nam',
          'Quang Ngai',
          'Quang Tri',
          'Thua Thien Hue'
        ]
      },
      {
        'CTKD 4': [
          'Dien Bien',
          'Ha Nam',
          'Hoa Binh',
          'Lai Chau',
          'Lao Cai',
          'Nam Dinh',
          'Ninh Binh',
          'Phu Tho',
          'Son La',
          'Vinh Phuc',
          'Yen Bai'
        ]
      },
      {
        'CTKD 5': [
          'Bac Giang',
          'Bac Kan',
          'Bac Ninh',
          'Cao Bang',
          'Ha Giang',
          'Tuyen Quang',
          'Hai Duong',
          'Hai Phong',
          'Hung Yen',
          'Lang Son',
          'Quang Ninh',
          'Thai Binh',
          'Thai Nguyen'
        ]
      },
      {
        'CTKD 6': ['Ha Tinh', 'Nghe An', 'Quang Binh', 'Thanh Hoa']
      },
      {
        'CTKD 7': ['Dak Lak', 'Dak Nong', 'Gia Lai', 'Khanh Hoa', 'Kon Tum','Phu Yen']
      },
      {
        'CTKD 8': [
          'Ba Ria - Vung Tau',
          'Binh Duong',
          'Binh Phuoc',
          'Binh Thuan',
          'Dong Nai',
          'Lam Dong',
          'Long An',
          'Ninh Thuan',
          'Tay Ninh'
        ]
      },
      {
        'CTKD 9': [
          'An Giang',
          'Bac Lieu',
          'Ben Tre',
          'Ca Mau',
          'Can Tho',
          'Dong Thap',
          'Hau Giang',
          'Kien Giang',
          'Soc Trang',
          'Tien Giang',
          'Tra Vinh',
          'Vinh Long'
        ]
      }
    ]
  },
  {
    VDL: [
      {
        'Mien Bac': [
          'Bac Can',
          'Bac Giang',
          'Bac Ninh',
          'Cao Bang',
          'Dien Bien',
          'Ha Giang',
          'Ha Nam',
          'Ha Noi',
          'Ha Tinh',
          'Hai Duong',
          'Hai Phong',
          'Hoa Binh',
          'Hung Yen',
          'Lai Chau',
          'Lang Son',
          'Lao Cai',
          'Nam Dinh',
          'Nghe An',
          'Ninh Binh',
          'Phu Tho',
          'Quang Binh',
          'Quang Ninh',
          'Son La',
          'Thai Binh',
          'Thai Nguyen',
          'Thanh Hoa',
          'Tuyen Quang',
          'Vinh Phuc',
          'Yen Bai'
        ]
      },
      {
        'Mien Trung': [
          'Binh Dinh',
          'Da Nang',
          'Dac Nong',
          'Dak Lak',
          'Gia Lai',
          'Khanh Hoa',
          'Kon Tum',
          'Phu Yen',
          'Quang Nam',
          'Quang Ngai',
          'Quang Tri',
          'TT-Hue'
        ]
      },
      {
        'Mien Nam': [
          'An Giang',
          'Ba Ria Vung Tau',
          'Bac Lieu',
          'Ben Tre',
          'Binh Duong',
          'Binh Phuoc',
          'Binh Thuan',
          'Ca Mau',
          'Can Tho',
          'Dong Nai',
          'Dong Thap',
          'Hau Giang',
          'Kien Giang',
          'Lam Dong',
          'Long An',
          'Ninh Thuan',
          'Soc Trang',
          'Tay Ninh',
          'Tien Giang',
          'Tp Ho Chi Minh',
          'Tra Vinh',
          'Vinh Long'
        ]
      }
    ]
  }
];
export const MenuDataThueBao = [
  {
    TTML: []
  },
  {
    CTKD: [
      {
        'CTKD 1': ['Ha Noi 1', 'Ha Noi 2', 'Ha Noi 3']
      },
      {
        'CTKD 2': ['Ho Chi Minh 1', 'Ho Chi Minh 2', 'Thu Duc']
      },
      {
        'CTKD 3': [
          'Binh Dinh',
          'Da Nang 1',
          'Da Nang 2',
          
          'Quang Nam',
          'Quang Ngai',
          'Quang Tri',
          'Thua Thien Hue'
        ]
      },
      {
        'CTKD 4': [
          'Dien Bien',
          'Ha Nam',
          'Hoa Binh',
          'Lai Chau',
          'Lao Cai',
          'Nam Dinh',
          'Ninh Binh',
          'Phu Tho',
          'Son La',
          'Vinh Phuc',
          'Yen Bai'
        ]
      },
      {
        'CTKD 5': [
          'Bac Giang',
          'Bac Kan',
          'Bac Ninh',
          'Cao Bang',
          'Ha Giang',
          'Tuyen Quang',
          'Hai Duong',
          'Hai Phong',
          'Hung Yen',
          'Lang Son',
          'Quang Ninh',
          'Thai Binh',
          'Thai Nguyen'
        ]
      },
      {
        'CTKD 6': ['Ha Tinh', 'Nghe An', 'Quang Binh', 'Thanh Hoa']
      },
      {
        'CTKD 7': ['Dak Lak', 'Dak Nong', 'Gia Lai', 'Khanh Hoa', 'Kon Tum','Phu Yen']
      },
      {
        'CTKD 8': [
          'Ba Ria - Vung Tau',
          'Binh Duong',
          'Binh Phuoc',
          'Binh Thuan',
          'Dong Nai',
          'Lam Dong',
          'Long An',
          'Ninh Thuan',
          'Tay Ninh'
        ]
      },
      {
        'CTKD 9': [
          'An Giang',
          'Bac Lieu',
          'Ben Tre',
          'Ca Mau',
          'Can Tho',
          'Dong Thap',
          'Hau Giang',
          'Kien Giang',
          'Soc Trang',
          'Tien Giang',
          'Tra Vinh',
          'Vinh Long'
        ]
      }
    ]
  },
  {
    VDL: [
      {
        'Mien Bac': [
          'Bac Can',
          'Bac Giang',
          'Bac Ninh',
          'Cao Bang',
          'Dien Bien',
          'Ha Giang',
          'Ha Nam',
          'Ha Noi',
          'Ha Tinh',
          'Hai Duong',
          'Hai Phong',
          'Hoa Binh',
          'Hung Yen',
          'Lai Chau',
          'Lang Son',
          'Lao Cai',
          'Nam Dinh',
          'Nghe An',
          'Ninh Binh',
          'Phu Tho',
          'Quang Binh',
          'Quang Ninh',
          'Son La',
          'Thai Binh',
          'Thai Nguyen',
          'Thanh Hoa',
          'Tuyen Quang',
          'Vinh Phuc',
          'Yen Bai'
        ]
      },
      {
        'Mien Trung': [
          'Binh Dinh',
          'Da Nang',
          'Dac Nong',
          'Dak Lak',
          'Gia Lai',
          'Khanh Hoa',
          'Kon Tum',
          'Phu Yen',
          'Quang Nam',
          'Quang Ngai',
          'Quang Tri',
          'TT-Hue'
        ]
      },
      {
        'Mien Nam': [
          'An Giang',
          'Ba Ria Vung Tau',
          'Bac Lieu',
          'Ben Tre',
          'Binh Duong',
          'Binh Phuoc',
          'Binh Thuan',
          'Ca Mau',
          'Can Tho',
          'Dong Nai',
          'Dong Thap',
          'Hau Giang',
          'Kien Giang',
          'Lam Dong',
          'Long An',
          'Ninh Thuan',
          'Soc Trang',
          'Tay Ninh',
          'Tien Giang',
          'Tp Ho Chi Minh',
          'Tra Vinh',
          'Vinh Long'
        ]
      }
    ]
  }
];
export const MenuCLCore = [
  {
    name: 'Mien Bac',
    listKPI: ['Core CS', 'Core PSLTE', 'VASIN']
  },
  {
    name: 'Mien Trung',
    listKPI: ['Core CS', 'Core PSLTE', 'VASIN']
  },
  {
    name: 'Mien Nam',
    listKPI: ['Core CS', 'Core PSLTE', 'VASIN']
  }
];
export const MenuDataTN5G = [
  {
    TTML: [
      {
        'Mien Nam': ['DVT Dong']
      }
    ]
  },
  {
    CTKD: [
      {
        'CTKD 2': ['Ho Chi Minh 1']
      }
    ]
  },
  {
    VDL: [
      {
        'Mien Nam': ['Tp Ho Chi Minh']
      }
    ]
  }
];
export const MenuDiemHTMTData = [
  {
    TTML: [
      {
        'Mien Bac': [
          'DVT Ha Noi 1',
          'DVT Ha Noi 2',
          'DVT Hai Phong',
          'DVT Nam Dinh',
          'DVT Nghe An',
          'DVT Thai Nguyen',
          'DVT Vinh Phuc'
        ]
      },
      {
        'Mien Trung': ['DVT Binh Dinh', 'DVT Da Nang', 'DVT Daklak']
      },
      {
        'Mien Nam': [
          'DVT Binh Duong',
          'DVT Can Tho',
          'DVT Dong',
          'DVT Dong Nai',
          'DVT Tay',
          'DVT Tien Giang'
        ]
      }
    ]
  },
  {
    VDL: [
      {
        'Mien Bac': [
          'Bac Can',
          'Bac Giang',
          'Bac Ninh',
          'Cao Bang',
          'Dien Bien',
          'Ha Giang',
          'Ha Nam',
          'Ha Noi',
          'Ha Tinh',
          'Hai Duong',
          'Hai Phong',
          'Hoa Binh',
          'Hung Yen',
          'Lai Chau',
          'Lang Son',
          'Lao Cai',
          'Nam Dinh',
          'Nghe An',
          'Ninh Binh',
          'Phu Tho',
          'Quang Binh',
          'Quang Ninh',
          'Son La',
          'Thai Binh',
          'Thai Nguyen',
          'Thanh Hoa',
          'Tuyen Quang',
          'Vinh Phuc',
          'Yen Bai'
        ]
      },
      {
        'Mien Trung': [
          'Binh Dinh',
          'Da Nang',
          'Dac Nong',
          'Dak Lak',
          'Gia Lai',
          'Khanh Hoa',
          'Kon Tum',
          'Phu Yen',
          'Quang Nam',
          'Quang Ngai',
          'Quang Tri',
          'TT-Hue'
        ]
      },
      {
        'Mien Nam': [
          'An Giang',
          'Ba Ria Vung Tau',
          'Bac Lieu',
          'Ben Tre',
          'Binh Duong',
          'Binh Phuoc',
          'Binh Thuan',
          'Ca Mau',
          'Can Tho',
          'Dong Nai',
          'Dong Thap',
          'Hau Giang',
          'Kien Giang',
          'Lam Dong',
          'Long An',
          'Ninh Thuan',
          'Soc Trang',
          'Tay Ninh',
          'Tien Giang',
          'Tp Ho Chi Minh',
          'Tra Vinh',
          'Vinh Long'
        ]
      }
    ]
  }
];
export const MenuDataMoreLV = [
  {
    TTML: [
      {
        'Mien Bac': [
          {
            'DVT Ha Noi 1': ['TVT1', 'TVT2', 'TVT3']
          },
          {
            'DVT Ha Noi 2': ['TVT1', 'TVT2', 'TVT3']
          },
          {
            'DVT Hai Phong': [
              'TVT Hai Duong',
              'TVT Hung Yen',
              'TVT Quang Ninh',
              'TVT Thai Binh'
            ]
          },
          {
            'DVT Nam Dinh': [
              'TVT Ha Nam',
              'TVT Hoa Binh',
              'TVT Nam Dinh',
              'TVT Ninh Binh'
            ]
          },
          {
            'DVT Nghe An': [
              'TVT Ha Tinh',
              'TVT Nghe An',
              'TVT Quang Binh',
              'TVT Thanh Hoa'
            ]
          },
          {
            'DVT Thai Nguyen': [
              'TVT Bac Can',
              'TVT Bac Giang',
              'TVT Bac Ninh',
              'TVT Cao Bang',
              'TVT Ha Giang',
              'TVT Lang Son',
              'TVT Thai Nguyen',
              'TVT Tuyen Quang'
            ]
          },
          {
            'DVT Vinh Phuc': [
              'TVT Dien Bien',
              'TVT Lai Chau',
              'TVT Lao Cai',
              'TVT Phu Tho',
              'TVT Son La',
              'TVT Vinh Phuc',
              'TVT Yen Bai'
            ]
          }
        ]
      },
      {
        'Mien Trung': [
          {
            'DVT Binh Dinh': [
              'TVT Binh Dinh',
              'TVT Khanh Hoa',
              'TVT Phu Yen',
              'TVT Quang Ngai'
            ]
          },
          {
            'DVT Da Nang': [
              'TVT Da Nang',
              'TVT Hue',
              'TVT Quang Nam',
              'TVT Quang Tri'
            ]
          },
          {
            'DVT Daklak': [
              'TVT Dac Lac',
              'TVT Dac Nong',
              'TVT Gia Lai',
              'TVT KonTum'
            ]
          }
        ]
      },
      {
        'Mien Nam': [
          {
            'DVT Binh Duong': [
              'TVT Binh Duong',
              'TVT Binh phuoc',
              'TVT Long an',
              'TVT Tay Ninh'
            ]
          },
          {
            'DVT Can Tho': [
              'TVT An Giang',
              'TVT Bac Lieu',
              'TVT Ca Mau',
              'TVT Can Tho',
              'TVT Hau Giang',
              'TVT Kien Giang',
              'TVT Soc Trang'
            ]
          },
          {
            'DVT Dong': ['TVT 1', 'TVT 2', 'TVT 3', 'TVT 4']
          },
          {
            'DVT Dong Nai': [
              'TVT Bao Loc',
              'TVT Bien Hoa',
              'TVT Binh Thuan',
              'TVT Da Lat',
              'TVT Long Khanh',
              'TVT Ninh Thuan',
              'TVT Vung Tau'
            ]
          },
          {
            'DVT Tay': ['TVT 5', 'TVT 6', 'TVT 7', 'TVT 8']
          },
          {
            'DVT Tien Giang': [
              'TVT Ben Tre',
              'TVT Dong Thap',
              'TVT Tien Giang',
              'TVT Tra Vinh',
              'TVT Vinh Long'
            ]
          }
        ]
      }
    ]
  },
  {
    CTKD: [
      {
        'CTKD 1': [
          {
            'Ha Noi 1': [
              'Ba Dinh',
              'Bac Tu Liem',
              'Cau Giay',
              'Dong Da',
              'Hai Ba Trung',
              'Hoan Kiem',
              'Hoang Mai',
              'Nam Tu Liem',
              'Tay Ho',
              'Thanh Tri',
              'Thanh Xuan',
              'Tu Liem'
            ]
          },
          {
            'Ha Noi 2': ['Dong Anh', 'Gia Lam', 'Long Bien', 'Me Linh', 'Soc Son']
          },
          {
            'Ha Noi 3': [
              'Ba Vi',
              'Chuong My',
              'Dan Phuong',
              'Ha Dong',
              'Hoai Duc',
              'My Duc',
              'Phu Xuyen',
              'Phuc Tho',
              'Quoc Oai',
              'Son Tay',
              'Thach That',
              'Thanh Oai',
              'Thuong Tin',
              'Ung Hoa'
            ]
          }
        ]
      },
      {
        'CTKD 2': [
          {
            'Ho Chi Minh 1': [
              'Binh Thanh',
              'Can Gio',
              'Nha Be',
              'Phu Nhuan',
              'Q.1',
              'Q.10',
              'Q.11',
              'Q.3',
              'Q.4',
              'Q.5',
              'Q.7'
            ]
          },
          {
            'Ho Chi Minh 2': [
              'Binh Chanh',
              'Binh Tan',
              'Cu Chi',
              'Go Vap',
              'Hoc Mon',
              'Q.12',
              'Q.6',
              'Q.8',
              'Tan Binh',
              'Tan Phu'
            ]
          },
          { 'Thu Duc': ['Q.2', 'Q.9', 'Thu Duc'] }
        ]
      },

      {
        'CTKD 3': [
          {
            'Binh Dinh': [
              'An Lao',
              'An Nhon',
              'Hoai An',
              'Hoai Nhon',
              'Phu Cat',
              'Phu My',
              'Qui Nhon',
              'Tay Son',
              'Tuy Phuoc',
              'Van Canh',
              'Vinh Thanh'
            ]
          },
          {
            'Da Nang 1': [
              'Hai Chau',
              'Thanh Khe',
              'Bac Hoa Vang',
              'Cam Le',
              'Hoa Vang',
              'Hoang Sa',
              'Lien Chieu',
              'Ngu Hanh Son',
              'Son Tra'
            ]
          },
          {
            'Da Nang 2': [
              'Cam Le',
              'Hoa Vang',
              'Hoang Sa',
              'Lien Chieu',
              'Ngu Hanh Son',
              'Son Tra'
            ]
          },
         
          {
            'Quang Nam': [
              'Bac Tra My',
              'Dai Loc',
              'Dien Ban',
              'Dong Giang',
              'Duy Xuyen',
              'Hiep Duc',
              'Hoi An',
              'Nam Giang',
              'Nam Tra My',
              'Nong Son',
              'Nui Thanh',
              'Phu Ninh',
              'Phuoc Son',
              'Que Son',
              'Tam Ky',
              'Tay Giang',
              'Thang Binh',
              'Tien Phuoc'
            ]
          },
          {
            'Quang Ngai': [
              'Ba To',
              'Binh Son',
              'Duc Pho',
              'Ly Son',
              'Minh Long',
              'Mo Duc',
              'Nghia Hanh',
              'Quang Ngai',
              'Son Ha',
              'Son Tay',
              'Son Tinh',
              'Tay Tra',
              'Tra Bong',
              'Tu Nghia'
            ]
          },
          {
            'Quang Tri': [
              'Cam Lo',
              'Con Co',
              'Da Krong',
              'Dong Ha',
              'Gio Linh',
              'Hai Lang',
              'Huong Hoa',
              'Quang Tri',
              'Trieu Phong',
              'Vinh Linh'
            ]
          },
          {
            'Thua Thien Hue': [
              'A Luoi',
              'Hue',
              'Huong Thuy',
              'Huong Tra',
              'Nam Dong',
              'Phong Dien',
              'Phu Loc',
              'Phu Vang',
              'Quang Dien'
            ]
          }
        ]
      },
      {
        'CTKD 4': [
          {
            'Dien Bien': [
              'Dien Bien',
              'Dien Bien Dong',
              'Dien Bien Phu',
              'Muong Ang',
              'Muong Cha',
              'Muong Lay',
              'Muong Nhe',
              'Nam Po',
              'Tua Chua',
              'Tuan Giao'
            ]
          },
          {
            'Ha Nam': [
              'Binh Luc',
              'Duy Tien',
              'Kim Bang',
              'Ly Nhan',
              'Phu Ly',
              'Thanh Liem'
            ]
          },
          {
            'Hoa Binh': [
              'Cao Phong',
              'Da Bac',
              'Hoa Binh',
              'Kim Boi',
              'Ky Son',
              'Lac Son',
              'Lac Thuy',
              'Luong Son',
              'Mai Chau',
              'Tan Lac',
              'Yen Thuy'
            ]
          },
          {
            'Lai Chau': [
              'Lai Chau',
              'Muong Te',
              'Nam Nhun',
              'Phong Tho',
              'Sin Ho',
              'Tam Duong',
              'Tan Uyen',
              'Than Uyen'
            ]
          },
          {
            'Lao Cai': [
              'Bac Ha',
              'Bao Thang',
              'Bao Yen',
              'Bat Xat',
              'Lao Cai',
              'Muong Khuong',
              'Sa Pa',
              'Si Ma Cai',
              'Van Ban'
            ]
          },
          {
            'Nam Dinh': [
              'Giao Thuy',
              'Hai Hau',
              'My Loc',
              'Nam Dinh',
              'Nam Truc',
              'Nghia Hung',
              'Truc Ninh',
              'Vu Ban',
              'Xuan Truong',
              'Y Yen'
            ]
          },
          {
            'Ninh Binh': [
              'Gia Vien',
              'Hoa Lu',
              'Kim Son',
              'Nho Quan',
              'Ninh Binh',
              'Tam Diep',
              'Yen Khanh',
              'Yen Mo'
            ]
          },
          {
            'Phu Tho': [
              'Cam Khe',
              'Doan Hung',
              'Ha Hoa',
              'Lam Thao',
              'Phu Ninh',
              'Phu Tho',
              'Tam Nong',
              'Tan Son',
              'Thanh Ba',
              'Thanh Son',
              'Thanh Thuy',
              'Viet Tri',
              'Yen Lap'
            ]
          },
          {
            'Son La': [
              'Bac Yen',
              'Mai Son',
              'Moc Chau',
              'Muong La',
              'Phu Yen',
              'Quynh Nhai',
              'Son La',
              'Song Ma',
              'Sop Cop',
              'Thuan Chau',
              'Van Ho',
              'Yen Chau'
            ]
          },
          {
            'Vinh Phuc': [
              'Binh Xuyen',
              'Lap Thach',
              'Phuc Yen',
              'Song Lo',
              'Tam Dao',
              'Tam Duong',
              'Vinh Tuong',
              'Vinh Yen',
              'Yen Lac'
            ]
          },
          {
            'Yen Bai': [
              'Luc Yen',
              'Mu Cang Chai',
              'Nghia Lo',
              'Tram Tau',
              'Tran Yen',
              'Van Chan',
              'Van Yen',
              'Yen Bai',
              'Yen Binh'
            ]
          }
        ]
      },
      {
        'CTKD 5': [
          {
            'Bac Giang': [
              'Bac Giang',
              'Hiep Hoa',
              'Lang Giang',
              'Luc Nam',
              'Luc Ngan',
              'Son Dong',
              'Tan Yen',
              'Viet Yen',
              'Yen Dung',
              'Yen The'
            ]
          },
          {
            'Bac Kan': [
              'Ba Be',
              'Bac Kan',
              'Bach Thong',
              'Cho Don',
              'Cho Moi',
              'Na Ri',
              'Ngan Son',
              'Pac Nam'
            ]
          },
          {
            'Bac Ninh': [
              'Bac Ninh',
              'Gia Binh',
              'Luong Tai',
              'Que Vo',
              'Thuan Thanh',
              'Tien Du',
              'Tu Son',
              'Yen Phong'
            ]
          },
          {
            'Cao Bang': [
              'Bao Lac',
              'Bao Lam',
              'Cao Bang',
              'Ha Lang',
              'Ha Quang',
              'Hoa An',
              'Nguyen Binh',
              'Phuc Hoa',
              'Quang Uyen',
              'Thach An',
              'Thong Nong',
              'Tra Linh',
              'Trung Khanh'
            ]
          },
          {
            'Ha Giang': [
              'Bac Me',
              'Bac Quang',
              'Chiem Hoa',
              'Dong Van',
              'Ha Giang',
              'Hoang Su Phi',
              'Meo Vac',
              'Quan Ba',
              'Quang Binh',
              'Vi Xuyen',
              'Xin Man',
              'Yen Minh'
            ]
          },
          {
            'Tuyen Quang': [
              'Chiem Hoa',
              'Ham Yen',
              'Lam Binh',
              'Na Hang',
              'Son Duong',
              'Tuyen Quang',
              'Yen Son'
            ]
          },
          {
            'Hai Duong': [
              'Binh Giang',
              'Cam Giang',
              'Chi Linh',
              'Gia Loc',
              'Hai Duong',
              'Kim Thanh',
              'Kinh Mon',
              'Nam Sach',
              'Ninh Giang',
              'Thanh Ha',
              'Thanh Mien',
              'Tu Ky'
            ]
          },
          {
            'Hai Phong': [
              'An Duong',
              'An Lao',
              'Bach Long Vi',
              'Cat Hai',
              'Do Son',
              'Duong Kinh',
              'Hai An',
              'Hong Bang',
              'Kien An',
              'Kien Thuy',
              'Le Chan',
              'Ngo Quyen',
              'Thuy Nguyen',
              'Tien Lang',
              'Vinh Bao'
            ]
          },
          {
            'Hung Yen': [
              'An Thi',
              'Hung Yen',
              'Khoai Chau',
              'Kim Dong',
              'My Hao',
              'Phu Cu',
              'Tien Lu',
              'Van Giang',
              'Van Lam',
              'Yen My'
            ]
          },
          {
            'Lang Son': [
              'Bac Son',
              'Binh Gia',
              'Cao Loc',
              'Chi Lang',
              'Dinh Lap',
              'Huu Lung',
              'Lang Son',
              'Loc Binh',
              'Trang Dinh',
              'Van Lang',
              'Van Quan'
            ]
          },
          {
            'Quang Ninh': [
              'Dong Trieu',
              'Ha Long',
              'Hoanh Bo',
              'Uong Bi',
              'Yen Hung',
              'Ba Che',
              'Binh Lieu',
              'Cam Pha',
              'Co To',
              'Dam Ha',
              'Hai Ha',
              'Mong Cai',
              'Tien Yen',
              'Van Don'
            ]
          },

          {
            'Thai Binh': [
              'Dong Hung',
              'Hung Ha',
              'Kien Xuong',
              'Quynh Phu',
              'Thai Binh',
              'Thai Thuy',
              'Tien Hai',
              'Vu Thu'
            ]
          },
          {
            'Thai Nguyen': [
              'Dai Tu',
              'Dinh Hoa',
              'Dong Hy',
              'Pho Yen',
              'Phu Binh',
              'Phu Luong',
              'Song Cong',
              'Thai Nguyen',
              'Vo Nhai'
            ]
          }
        ]
      },
      {
        'CTKD 6': [
          {
            'Ha Tinh': [
              'Cam Xuyen',
              'Can Loc',
              'Duc Tho',
              'Ha Tinh',
              'Hong Linh',
              'Huong Khe',
              'Huong Son',
              'Ky Anh',
              'Loc Ha',
              'Nghi Xuan',
              'Thach Ha',
              'TX Ky Anh',
              'Vu Quang'
            ]
          },
          {
            'Nghe An': [
              'Anh Son',
              'Con Cuong',
              'Cua Lo',
              'Dien Chau',
              'Do Luong',
              'Hoang Mai',
              'Hung Nguyen',
              'Ky Son',
              'Nam Dan',
              'Nghi Loc',
              'Nghia Dan',
              'Que Phong',
              'Quy Chau',
              'Quy Hop',
              'Quynh Luu',
              'Tan Ky',
              'Thai Hoa',
              'Thanh Chuong',
              'Tuong Duong',
              'Vinh',
              'Yen Thanh'
            ]
          },
          {
            'Quang Binh': [
              'Ba Don',
              'Bo Trach',
              'Dong Hoi',
              'Le Thuy',
              'Minh Hoa',
              'Quang Ninh',
              'Quang Trach',
              'Tuyen Hoa'
            ]
          },
          {
            'Thanh Hoa': [
              'Ba Thuoc',
              'Bim Son',
              'Cam Thuy',
              'Dong Son',
              'Ha Trung',
              'Hau Loc',
              'Hoang Hoa',
              'Lang Chanh',
              'Muong Lat',
              'Nga Son',
              'Ngoc Lac',
              'Nhu Thanh',
              'Nhu Xuan',
              'Nong Cong',
              'Quan Hoa',
              'Quan Son',
              'Quang Xuong',
              'Sam Son',
              'Thach Thanh',
              'Thanh Hoa',
              'Thieu Hoa',
              'Tho Xuan',
              'Thuong Xuan',
              'Tinh Gia',
              'Trieu Son',
              'Vinh Loc',
              'Yen Dinh'
            ]
          }
        ]
      },
      {
        'CTKD 7': [
          {
            'Dak Lak': [
              'Buon Don',
              'Buon Ho',
              'Buon Ma Thuot',
              'Cu Kuin',
              "Cu M'gar",
              "Ea H'leo",
              'Ea Kar',
              'Ea Sup',
              'Krong A Na',
              'Krong Bong',
              'Krong Buk',
              'Krong Nang',
              'Krong Pac',
              'Lak',
              "M'Drak"
            ]
          },
          {
            'Dak Nong': [
              'Cu Jut',
              'Dak Glong',
              'Dak Mil',
              "Dak R'Lap",
              'Dak Song',
              'Gia Nghia',
              'Krong No',
              'Tuy Duc'
            ]
          },
          {
            'Gia Lai': [
              'An Khe',
              'Ayun Pa',
              'Chu Pah',
              'Chu Prong',
              'Chu Puh',
              'Chu Se',
              'Dak Doa',
              'Dak Po',
              'Duc Co',
              'Ia Grai',
              'Ia Pa',
              'KBang',
              'Kong Chro',
              'Krong Pa',
              'Mang Yang',
              'Phu Thien',
              'Pleiku'
            ]
          },
          {
            'Khanh Hoa': [
              'Cam Lam',
              'Cam Ranh',
              'Dien Khanh',
              'Khanh Son',
              'Khanh Vinh',
              'Nha Trang',
              'Ninh Hoa',
              'Truong Sa',
              'Van Ninh'
            ]
          },
          {
            'Kon Tum': [
              'Dak Glei',
              'Dak Ha',
              'Dak To',
              'HDrai',
              'Kon Plong',
              'Kon Ray',
              'Kon Tum',
              'Ngoc Hoi',
              'Sa Thay',
              'Tu Mo Rong'
            ]
          },
          {
            'Phu Yen': [
              'Dong Hoa',
              'Dong Xuan',
              'Phu Hoa',
              'Son Hoa',
              'Song Cau',
              'Song Hinh',
              'Tay Hoa',
              'Tuy An',
              'Tuy Hoa'
            ]
          }
        ]
      },
      {
        'CTKD 8': [
          {
            'Ba Ria - Vung Tau': [
              'Ba Ria',
              'Chau Duc',
              'Con Dao',
              'Dat Do',
              'Long Dien',
              'Tan Thanh',
              'Vung Tau',
              'Xuyen Moc'
            ]
          },
          {
            'Binh Duong': [
              'Bac Tan Uyen',
              'Bau Bang',
              'Ben Cat',
              'Dau Tieng',
              'Di An',
              'Phu Giao',
              'Tan Uyen',
              'Thu Dau Mot',
              'Thuan An'
            ]
          },
          {
            'Binh Phuoc': [
              'Binh Long',
              'Bu Dang',
              'Bu Dop',
              'Bu Gia Map',
              'Chon Thanh',
              'Dong Phu',
              'Dong Xoai',
              'Hon Quan',
              'Loc Ninh',
              'Phu Rieng',
              'Phuoc Long'
            ]
          },
          {
            'Binh Thuan': [
              'Bac Binh',
              'Duc Linh',
              'Ham Tan',
              'Ham Thuan Bac',
              'Ham Thuan Nam',
              'La Gi',
              'Phan Thiet',
              'Phu Quy',
              'Tanh Linh',
              'Tuy Phong'
            ]
          },
          {
            'Dong Nai': [
              'Bien Hoa',
              'Cam My',
              'Dinh Quan',
              'Long Khanh',
              'Long Thanh',
              'Nhon Trach',
              'Tan Phu',
              'Thong Nhat',
              'Trang Bom',
              'Vinh Cuu',
              'Xuan Loc'
            ]
          },
          {
            'Lam Dong': [
              'Bao Lam',
              'Bao Loc',
              'Cat Tien',
              'Da Huoai',
              'Da Lat',
              'Da Teh',
              'Dam Rong',
              'Di Linh',
              'Don Duong',
              'Duc Trong',
              'Lac Duong',
              'Lam Ha'
            ]
          },
          {
            'Long An': [
              'Ben Luc',
              'Can Duoc',
              'Can Giuoc',
              'Chau Thanh',
              'Duc Hoa',
              'Duc Hue',
              'Kien Tuong',
              'Moc Hoa',
              'Tan An',
              'Tan Hung',
              'Tan Thanh',
              'Tan Tru',
              'Thanh Hoa',
              'Thu Thua',
              'Vinh Hung'
            ]
          },
          {
            'Ninh Thuan': [
              'Bac Ai',
              'Ninh Hai',
              'Ninh Phuoc',
              'Ninh Son',
              'P.Rang-T.Cham',
              'Thuan Bac',
              'Thuan Nam'
            ]
          },
          {
            'Tay Ninh': [
              'Ben Cau',
              'Chau Thanh',
              'Duong Minh Chau',
              'Go Dau',
              'Hoa Thanh',
              'Tan Bien',
              'Tan Chau',
              'Tay Ninh',
              'Trang Bang'
            ]
          }
        ]
      },
      {
        'CTKD 9': [
          {
            'An Giang': [
              'An Phu',
              'Chau Doc',
              'Chau Phu',
              'Chau Thanh',
              'Cho Moi',
              'Long Xuyen',
              'Phu Tan',
              'Tan Chau',
              'Thoai Son',
              'Tinh Bien',
              'Tri Ton'
            ]
          },
          {
            'Bac Lieu': [
              'Bac Lieu',
              'Dong Hai',
              'Gia Rai',
              'Hoa Binh',
              'Hong Dan',
              'Phuoc Long',
              'Vinh Loi'
            ]
          },
          {
            'Ben Tre': [
              'Ba Tri',
              'Ben Tre',
              'Binh Dai',
              'Chau Thanh',
              'Cho Lach',
              'Giong Trom',
              'Mo Cay Bac',
              'Mo Cay Nam',
              'Thanh Phu'
            ]
          },
          {
            'Ca Mau': [
              'Ca Mau',
              'Cai Nuoc',
              'Dam Doi',
              'Nam Can',
              'Ngoc Hien',
              'Phu Tan',
              'Thoi Binh',
              'Tran Van Thoi',
              'U Minh'
            ]
          },
          {
            'Can Tho': [
              'Binh Thuy',
              'Cai Rang',
              'Co Do',
              'Ninh Kieu',
              'O Mon',
              'Phong Dien',
              'Thoi Lai',
              'Thot Not',
              'Vinh Thanh'
            ]
          },
          {
            'Dong Thap': [
              'Cao Lanh',
              'Chau Thanh',
              'Hong Ngu',
              'Lai Vung',
              'Lap Vo',
              'Sa Dec',
              'Tam Nong',
              'Tan Hong',
              'Thanh Binh',
              'Thap Muoi',
              'Tp Cao Lanh',
              'Tx Hong Ngu'
            ]
          },
          {
            'Hau Giang': [
              'Chau Thanh',
              'Chau Thanh A',
              'Long My',
              'Nga Bay',
              'Phung Hiep',
              'TX Long My',
              'Vi Thanh',
              'Vi Thuy'
            ]
          },
          {
            'Kien Giang': [
              'An Bien',
              'An Minh',
              'Chau Thanh',
              'Giang Thanh',
              'Giong Rieng',
              'Go Quao',
              'Ha Tien',
              'Hon Dat',
              'Kien Hai',
              'Kien Luong',
              'Phu Quoc',
              'Rach Gia',
              'Tan Hiep',
              'U Minh Thuong',
              'Vinh Thuan'
            ]
          },
          {
            'Soc Trang': [
              'Chau Thanh',
              'Cu Lao Dung',
              'Ke Sach',
              'Long Phu',
              'My Tu',
              'My Xuyen',
              'Nga Nam',
              'Soc Trang',
              'Thanh Tri',
              'Tran De',
              'Vinh Chau'
            ]
          },
          {
            'Tien Giang': [
              'Cai Be',
              'Cai Lay',
              'Chau Thanh',
              'Cho Gao',
              'Go Cong',
              'Go Cong Dong',
              'Go Cong Tay',
              'My Tho',
              'Tan Phu Dong',
              'Tan Phuoc',
              'TX Cai Lay'
            ]
          },
          {
            'Tra Vinh': [
              'Cang Long',
              'Cau Ke',
              'Cau Ngang',
              'Chau Thanh',
              'Duyen Hai',
              'Tieu Can',
              'Tra Cu',
              'Tra Vinh',
              'TX Duyen Hai'
            ]
          },
          {
            'Vinh Long': [
              'Binh Minh',
              'Binh Tan',
              'Long Ho',
              'Mang Thit',
              'Tam Binh',
              'Tra On',
              'Vinh Long',
              'Vung Liem'
            ]
          }
        ]
      }
    ]
  },
  {
    'Vùng Địa Lý': [
      {
        'Mien Bac': [
          {
            'Bac Can': [
              'Ba Be',
              'Bac Kan',
              'Bach Thong',
              'Cho Don',
              'Cho Moi',
              'Na Ri',
              'Ngan Son',
              'Pac Nam'
            ]
          },
          {
            'Bac Giang': [
              'Bac Giang',
              'Hiep Hoa',
              'Lang Giang',
              'Luc Nam',
              'Luc Ngan',
              'Son Dong',
              'Tan Yen',
              'Viet Yen',
              'Yen Dung',
              'Yen The'
            ]
          },
          {
            'Bac Ninh': [
              'Bac Ninh',
              'Gia Binh',
              'Luong Tai',
              'Que Vo',
              'Thuan Thanh',
              'Tien Du',
              'Tu Son',
              'Yen Phong'
            ]
          },
          {
            'Cao Bang': [
              'Bao Lac',
              'Bao Lam',
              'Cao Bang',
              'Ha Lang',
              'Ha Quang',
              'Hoa An',
              'Nguyen Binh',
              'Phuc Hoa',
              'Quang Uyen',
              'Thach An',
              'Thong Nong',
              'Tra Linh',
              'Trung Khanh'
            ]
          },
          {
            'Dien Bien': [
              'Dien Bien',
              'Dien Bien Dong',
              'Dien Bien Phu',
              'Muong Ang',
              'Muong Cha',
              'Muong Lay',
              'Muong Nhe',
              'Nam Po',
              'Tua Chua',
              'Tuan Giao'
            ]
          },
          {
            'Ha Giang': [
              'Bac Me',
              'Bac Quang',
              'Dong Van',
              'Ha Giang',
              'Hoang Su Phi',
              'Meo Vac',
              'Quan Ba',
              'Quang Binh',
              'Vi Xuyen',
              'Xin Man',
              'Yen Minh'
            ]
          },
          {
            'Ha Nam': [
              'Binh Luc',
              'Duy Tien',
              'Kim Bang',
              'Ly Nhan',
              'Phu Ly',
              'Thanh Liem'
            ]
          },
          {
            'Ha Noi': [
              'Ba Dinh',
              'Ba Vi',
              'Bac Tu Liem',
              'Cau Giay',
              'Chuong My',
              'Dan Phuong',
              'Dong Anh',
              'Dong Da',
              'Gia Lam',
              'Ha Dong',
              'Hai Ba Trung',
              'Hoai Duc',
              'Hoan Kiem',
              'Hoang Mai',
              'Long Bien',
              'Me Linh',
              'My Duc',
              'Nam Tu Liem',
              'Phu Xuyen',
              'Phuc Tho',
              'Quoc Oai',
              'Soc Son',
              'Son Tay',
              'Tay Ho',
              'Thach That',
              'Thanh Oai',
              'Thanh Tri',
              'Thanh Xuan',
              'Thuong Tin',
              'Tu Liem',
              'Ung Hoa'
            ]
          },
          {
            'Ha Tinh': [
              'Cam Xuyen',
              'Can Loc',
              'Duc Tho',
              'Ha Tinh',
              'Hong Linh',
              'Huong Khe',
              'Huong Son',
              'Ky Anh',
              'Loc Ha',
              'Nghi Xuan',
              'Thach Ha',
              'TX Ky Anh',
              'Vu Quang'
            ]
          },
          {
            'Hai Duong': [
              'Binh Giang',
              'Cam Giang',
              'Chi Linh',
              'Gia Loc',
              'Hai Duong',
              'Kim Thanh',
              'Kinh Mon',
              'Nam Sach',
              'Ninh Giang',
              'Thanh Ha',
              'Thanh Mien',
              'Tu Ky'
            ]
          },
          {
            'Hai Phong': [
              'An Duong',
              'An Lao',
              'Bach Long Vi',
              'Cat Hai',
              'Do Son',
              'Duong Kinh',
              'Hai An',
              'Hong Bang',
              'Kien An',
              'Kien Thuy',
              'Le Chan',
              'Ngo Quyen',
              'Thuy Nguyen',
              'Tien Lang',
              'Vinh Bao'
            ]
          },
          {
            'Hoa Binh': [
              'Cao Phong',
              'Da Bac',
              'Hoa Binh',
              'Kim Boi',
              'Ky Son',
              'Lac Son',
              'Lac Thuy',
              'Luong Son',
              'Mai Chau',
              'Tan Lac',
              'Yen Thuy'
            ]
          },
          {
            'Hung Yen': [
              'An Thi',
              'Hung Yen',
              'Khoai Chau',
              'Kim Dong',
              'My Hao',
              'Phu Cu',
              'Tien Lu',
              'Van Giang',
              'Van Lam',
              'Yen My'
            ]
          },
          {
            'Lai Chau': [
              'Lai Chau',
              'Muong Te',
              'Nam Nhun',
              'Phong Tho',
              'Sin Ho',
              'Tam Duong',
              'Tan Uyen',
              'Than Uyen'
            ]
          },
          {
            'Lang Son': [
              'Bac Son',
              'Binh Gia',
              'Cao Loc',
              'Chi Lang',
              'Dinh Lap',
              'Huu Lung',
              'Lang Son',
              'Loc Binh',
              'Trang Dinh',
              'Van Lang',
              'Van Quan'
            ]
          },
          {
            'Lao Cai': [
              'Bac Ha',
              'Bao Thang',
              'Bao Yen',
              'Bat Xat',
              'Lao Cai',
              'Muong Khuong',
              'Sa Pa',
              'Si Ma Cai',
              'Van Ban'
            ]
          },
          {
            'Nam Dinh': [
              'Giao Thuy',
              'Hai Hau',
              'My Loc',
              'Nam Dinh',
              'Nam Truc',
              'Nghia Hung',
              'Truc Ninh',
              'Vu Ban',
              'Xuan Truong',
              'Y Yen'
            ]
          },
          {
            'Nghe An': [
              'Anh Son',
              'Con Cuong',
              'Cua Lo',
              'Dien Chau',
              'Do Luong',
              'Hoang Mai',
              'Hung Nguyen',
              'Ky Son',
              'Nam Dan',
              'Nghi Loc',
              'Nghia Dan',
              'Que Phong',
              'Quy Chau',
              'Quy Hop',
              'Quynh Luu',
              'Tan Ky',
              'Thai Hoa',
              'Thanh Chuong',
              'Tuong Duong',
              'Vinh',
              'Yen Thanh'
            ]
          },
          {
            'Ninh Binh': [
              'Gia Vien',
              'Hoa Lu',
              'Kim Son',
              'Nho Quan',
              'Ninh Binh',
              'Tam Diep',
              'Yen Khanh',
              'Yen Mo'
            ]
          },
          {
            'Phu Tho': [
              'Cam Khe',
              'Doan Hung',
              'Ha Hoa',
              'Lam Thao',
              'Phu Ninh',
              'Phu Tho',
              'Tam Nong',
              'Tan Son',
              'Thanh Ba',
              'Thanh Son',
              'Thanh Thuy',
              'Viet Tri',
              'Yen Lap'
            ]
          },
          {
            'Quang Binh': [
              'Ba Don',
              'Bo Trach',
              'Dong Hoi',
              'Le Thuy',
              'Minh Hoa',
              'Quang Ninh',
              'Quang Trach',
              'Tuyen Hoa'
            ]
          },
          {
            'Quang Ninh': [
              'Ba Che',
              'Binh Lieu',
              'Cam Pha',
              'Co To',
              'Dam Ha',
              'Dong Trieu',
              'Ha Long',
              'Hai Ha',
              'Hoanh Bo',
              'Mong Cai',
              'Tien Yen',
              'Uong Bi',
              'Van Don',
              'Yen Hung'
            ]
          },
          {
            'Son La': [
              'Bac Yen',
              'Mai Son',
              'Moc Chau',
              'Muong La',
              'Phu Yen',
              'Quynh Nhai',
              'Son La',
              'Song Ma',
              'Sop Cop',
              'Thuan Chau',
              'Van Ho',
              'Yen Chau'
            ]
          },
          {
            'Thai Binh': [
              'Dong Hung',
              'Hung Ha',
              'Kien Xuong',
              'Quynh Phu',
              'Thai Binh',
              'Thai Thuy',
              'Tien Hai',
              'Vu Thu'
            ]
          },
          {
            'Thai Nguyen': [
              'Dai Tu',
              'Dinh Hoa',
              'Dong Hy',
              'Pho Yen',
              'Phu Binh',
              'Phu Luong',
              'Song Cong',
              'Thai Nguyen',
              'Vo Nhai'
            ]
          },
          {
            'Thanh Hoa': [
              'Ba Thuoc',
              'Bim Son',
              'Cam Thuy',
              'Dong Son',
              'Ha Trung',
              'Hau Loc',
              'Hoang Hoa',
              'Lang Chanh',
              'Muong Lat',
              'Nga Son',
              'Ngoc Lac',
              'Nhu Thanh',
              'Nhu Xuan',
              'Nong Cong',
              'Quan Hoa',
              'Quan Son',
              'Quang Xuong',
              'Sam Son',
              'Thach Thanh',
              'Thanh Hoa',
              'Thieu Hoa',
              'Tho Xuan',
              'Thuong Xuan',
              'Tinh Gia',
              'Trieu Son',
              'Vinh Loc',
              'Yen Dinh'
            ]
          },
          {
            'Tuyen Quang': [
              'Chiem Hoa',
              'Ham Yen',
              'Lam Binh',
              'Na Hang',
              'Son Duong',
              'Tuyen Quang',
              'Yen Son'
            ]
          },
          {
            'Vinh Phuc': [
              'Binh Xuyen',
              'Lap Thach',
              'Phuc Yen',
              'Song Lo',
              'Tam Dao',
              'Tam Duong',
              'Vinh Tuong',
              'Vinh Yen',
              'Yen Lac'
            ]
          },
          {
            'Yen Bai': [
              'Luc Yen',
              'Mu Cang Chai',
              'Nghia Lo',
              'Tram Tau',
              'Tran Yen',
              'Van Chan',
              'Van Yen',
              'Yen Bai',
              'Yen Binh'
            ]
          }
        ]
      },
      {
        'Mien Trung': [
          {
            'Binh Dinh': [
              'An Lao',
              'An Nhon',
              'Hoai An',
              'Hoai Nhon',
              'Phu Cat',
              'Phu My',
              'Qui Nhon',
              'Tay Son',
              'Tuy Phuoc',
              'Van Canh',
              'Vinh Thanh'
            ]
          },
          {
            'Da Nang': [
              'Bac Hoa Vang',
              'Cam Le',
              'Hai Chau',
              'Hoa Vang',
              'Hoang Sa',
              'Lien Chieu',
              'Ngu Hanh Son',
              'Son Tra',
              'Thanh Khe'
            ]
          },
          {
            'Dac Nong': [
              'Cu Jut',
              'Dak Glong',
              'Dak Mil',
              "Dak R'Lap",
              'Dak Song',
              'Gia Nghia',
              'Krong No',
              'Tuy Duc'
            ]
          },
          {
            'Dak Lak': [
              'Buon Don',
              'Buon Ho',
              'Buon Ma Thuot',
              'Cu Kuin',
              "Cu M'gar",
              "Ea H'leo",
              'Ea Kar',
              'Ea Sup',
              'Krong A Na',
              'Krong Bong',
              'Krong Buk',
              'Krong Nang',
              'Krong Pac',
              'Lak',
              "M'Drak"
            ]
          },
          {
            'Gia Lai': [
              'An Khe',
              'Ayun Pa',
              'Chu Pah',
              'Chu Prong',
              'Chu Puh',
              'Chu Se',
              'Dak Doa',
              'Dak Po',
              'Duc Co',
              'Ia Grai',
              'Ia Pa',
              'KBang',
              'Kong Chro',
              'Krong Pa',
              'Mang Yang',
              'Phu Thien',
              'Pleiku'
            ]
          },
          {
            'Khanh Hoa': [
              'Cam Lam',
              'Cam Ranh',
              'Dien Khanh',
              'Khanh Son',
              'Khanh Vinh',
              'Nha Trang',
              'Ninh Hoa',
              'Truong Sa',
              'Van Ninh'
            ]
          },
          {
            'Kon Tum': [
              'Dak Glei',
              'Dak Ha',
              'Dak To',
              'HDrai',
              'Kon Plong',
              'Kon Ray',
              'Kon Tum',
              'Ngoc Hoi',
              'Sa Thay',
              'Tu Mo Rong'
            ]
          },
          {
            'Phu Yen': [
              'Dong Hoa',
              'Dong Xuan',
              'Phu Hoa',
              'Son Hoa',
              'Song Cau',
              'Song Hinh',
              'Tay Hoa',
              'Tuy An',
              'Tuy Hoa'
            ]
          },
          {
            'Quang Nam': [
              'Bac Tra My',
              'Dai Loc',
              'Dien Ban',
              'Dong Giang',
              'Duy Xuyen',
              'Hiep Duc',
              'Hoi An',
              'Nam Giang',
              'Nam Tra My',
              'Nong Son',
              'Nui Thanh',
              'Phu Ninh',
              'Phuoc Son',
              'Que Son',
              'Tam Ky',
              'Tay Giang',
              'Thang Binh',
              'Tien Phuoc'
            ]
          },
          {
            'Quang Ngai': [
              'Ba To',
              'Binh Son',
              'Duc Pho',
              'Ly Son',
              'Minh Long',
              'Mo Duc',
              'Nghia Hanh',
              'Quang Ngai',
              'Son Ha',
              'Son Tay',
              'Son Tinh',
              'Tay Tra',
              'Tra Bong',
              'Tu Nghia'
            ]
          },
          {
            'Quang Tri': [
              'Cam Lo',
              'Con Co',
              'Da Krong',
              'Dong Ha',
              'Gio Linh',
              'Hai Lang',
              'Huong Hoa',
              'Quang Tri',
              'Trieu Phong',
              'Vinh Linh'
            ]
          },
          {
            'TT-Hue': [
              'A Luoi',
              'Hue',
              'Huong Thuy',
              'Huong Tra',
              'Nam Dong',
              'Phong Dien',
              'Phu Loc',
              'Phu Vang',
              'Quang Dien'
            ]
          }
        ]
      },
      {
        'Mien Nam': [
          {
            'An Giang': [
              'An Phu',
              'Chau Doc',
              'Chau Phu',
              'Chau Thanh',
              'Cho Moi',
              'Long Xuyen',
              'Phu Tan',
              'Tan Chau',
              'Thoai Son',
              'Tinh Bien',
              'Tri Ton'
            ]
          },
          {
            'Ba Ria Vung Tau': [
              'Ba Ria',
              'Chau Duc',
              'Con Dao',
              'Dat Do',
              'Long Dien',
              'Tan Thanh',
              'Vung Tau',
              'Xuyen Moc'
            ]
          },
          {
            'Bac Lieu': [
              'Bac Lieu',
              'Dong Hai',
              'Gia Rai',
              'Hoa Binh',
              'Hong Dan',
              'Phuoc Long',
              'Vinh Loi'
            ]
          },
          {
            'Ben Tre': [
              'Ba Tri',
              'Ben Tre',
              'Binh Dai',
              'Chau Thanh',
              'Cho Lach',
              'Giong Trom',
              'Mo Cay Bac',
              'Mo Cay Nam',
              'Thanh Phu'
            ]
          },
          {
            'Binh Duong': [
              'Bac Tan Uyen',
              'Bau Bang',
              'Ben Cat',
              'Dau Tieng',
              'Di An',
              'Phu Giao',
              'Tan Uyen',
              'Thu Dau Mot',
              'Thuan An'
            ]
          },
          {
            'Binh Phuoc': [
              'Binh Long',
              'Bu Dang',
              'Bu Dop',
              'Bu Gia Map',
              'Chon Thanh',
              'Dong Phu',
              'Dong Xoai',
              'Hon Quan',
              'Loc Ninh',
              'Phu Rieng',
              'Phuoc Long'
            ]
          },
          {
            'Binh Thuan': [
              'Bac Binh',
              'Duc Linh',
              'Ham Tan',
              'Ham Thuan Bac',
              'Ham Thuan Nam',
              'La Gi',
              'Phan Thiet',
              'Phu Quy',
              'Tanh Linh',
              'Tuy Phong'
            ]
          },
          {
            'Ca Mau': [
              'Ca Mau',
              'Cai Nuoc',
              'Dam Doi',
              'Nam Can',
              'Ngoc Hien',
              'Phu Tan',
              'Thoi Binh',
              'Tran Van Thoi',
              'U Minh'
            ]
          },
          {
            'Can Tho': [
              'Binh Thuy',
              'Cai Rang',
              'Co Do',
              'Ninh Kieu',
              'O Mon',
              'Phong Dien',
              'Thoi Lai',
              'Thot Not',
              'Vinh Thanh'
            ]
          },
          {
            'Dong Nai': [
              'Bien Hoa',
              'Cam My',
              'Dinh Quan',
              'Long Khanh',
              'Long Thanh',
              'Nhon Trach',
              'Tan Phu',
              'Thong Nhat',
              'Trang Bom',
              'Vinh Cuu',
              'Xuan Loc'
            ]
          },
          {
            'Dong Thap': [
              'Cao Lanh',
              'Chau Thanh',
              'Hong Ngu',
              'Lai Vung',
              'Lap Vo',
              'Sa Dec',
              'Tam Nong',
              'Tan Hong',
              'Thanh Binh',
              'Thap Muoi',
              'Tp Cao Lanh',
              'Tx Hong Ngu'
            ]
          },
          {
            'Hau Giang': [
              'Chau Thanh',
              'Chau Thanh A',
              'Long My',
              'Nga Bay',
              'Phung Hiep',
              'TX Long My',
              'Vi Thanh',
              'Vi Thuy'
            ]
          },
          {
            'Kien Giang': [
              'An Bien',
              'An Minh',
              'Chau Thanh',
              'Giang Thanh',
              'Giong Rieng',
              'Go Quao',
              'Ha Tien',
              'Hon Dat',
              'Kien Hai',
              'Kien Luong',
              'Phu Quoc',
              'Rach Gia',
              'Tan Hiep',
              'U Minh Thuong',
              'Vinh Thuan'
            ]
          },
          {
            'Lam Dong': [
              'Bao Lam',
              'Bao Loc',
              'Cat Tien',
              'Da Huoai',
              'Da Lat',
              'Da Teh',
              'Dam Rong',
              'Di Linh',
              'Don Duong',
              'Duc Trong',
              'Lac Duong',
              'Lam Ha'
            ]
          },
          {
            'Long An': [
              'Ben Luc',
              'Can Duoc',
              'Can Giuoc',
              'Chau Thanh',
              'Duc Hoa',
              'Duc Hue',
              'Kien Tuong',
              'Moc Hoa',
              'Tan An',
              'Tan Hung',
              'Tan Thanh',
              'Tan Tru',
              'Thanh Hoa',
              'Thu Thua',
              'Vinh Hung'
            ]
          },
          {
            'Ninh Thuan': [
              'Bac Ai',
              'Ninh Hai',
              'Ninh Phuoc',
              'Ninh Son',
              'P.Rang-T.Cham',
              'Thuan Bac',
              'Thuan Nam'
            ]
          },
          {
            'Soc Trang': [
              'Chau Thanh',
              'Cu Lao Dung',
              'Ke Sach',
              'Long Phu',
              'My Tu',
              'My Xuyen',
              'Nga Nam',
              'Soc Trang',
              'Thanh Tri',
              'Tran De',
              'Vinh Chau'
            ]
          },
          {
            'Tay Ninh': [
              'Ben Cau',
              'Chau Thanh',
              'Duong Minh Chau',
              'Go Dau',
              'Hoa Thanh',
              'Tan Bien',
              'Tan Chau',
              'Tay Ninh',
              'Trang Bang'
            ]
          },
          {
            'Tien Giang': [
              'Cai Be',
              'Cai Lay',
              'Chau Thanh',
              'Cho Gao',
              'Go Cong',
              'Go Cong Dong',
              'Go Cong Tay',
              'My Tho',
              'Tan Phu Dong',
              'Tan Phuoc',
              'TX Cai Lay'
            ]
          },
          {
            'Tp Ho Chi Minh': [
              'Binh Chanh',
              'Binh Tan',
              'Binh Thanh',
              'Can Gio',
              'Cu Chi',
              'Go Vap',
              'Hoc Mon',
              'Nha Be',
              'Phu Nhuan',
              'Q.1',
              'Q.10',
              'Q.11',
              'Q.12',
              'Q.2',
              'Q.3',
              'Q.4',
              'Q.5',
              'Q.6',
              'Q.7',
              'Q.8',
              'Q.9',
              'Tan Binh',
              'Tan Phu',
              'Thu Duc'
            ]
          },
          {
            'Tra Vinh': [
              'Cang Long',
              'Cau Ke',
              'Cau Ngang',
              'Chau Thanh',
              'Duyen Hai',
              'Tieu Can',
              'Tra Cu',
              'Tra Vinh',
              'TX Duyen Hai'
            ]
          },
          {
            'Vinh Long': [
              'Binh Minh',
              'Binh Tan',
              'Long Ho',
              'Mang Thit',
              'Tam Binh',
              'Tra On',
              'Vinh Long',
              'Vung Liem'
            ]
          }
        ]
      }
    ]
  }
];

export const MenuDataTemp = [
  {
    TTML: [
      {
        'Mien Bac': [
          'DVT Ha Noi 1',
          'DVT Ha Noi 2',
          'DVT Hai Phong',
          'DVT Nam Dinh',
          'DVT Nghe An',
          'DVT Thai Nguyen',
          'DVT Vinh Phuc'
        ]
      },
      {
        'Mien Trung': ['DVT Binh Dinh', 'DVT Da Nang', 'DVT Daklak']
      },
      {
        'Mien Nam': [
          'DVT Binh Duong',
          'DVT Can Tho',
          'DVT Dong',
          'DVT Dong Nai',
          'DVT Tay',
          'DVT Tien Giang'
        ]
      }
    ]
  },
  {
    CTKD: [
      {
        'CTKD 1': ['Ha Noi 1', 'Ha Noi 2', 'Ha Noi 3']
      },
      {
        'CTKD 2': ['Ho Chi Minh 1', 'Ho Chi Minh 2', 'Thu Duc']
      },
      {
        'CTKD 3': [
          'Binh Dinh',
          'Da Nang 1',
          'Da Nang 2',
         
          'Quang Nam',
          'Quang Ngai',
          'Quang Tri',
          'Thua Thien Hue'
        ]
      },
      {
        'CTKD 4': [
          'Dien Bien',
          'Ha Nam',
          'Hoa Binh',
          'Lai Chau',
          'Lao Cai',
          'Nam Dinh',
          'Ninh Binh',
          'Phu Tho',
          'Son La',
          'Vinh Phuc',
          'Yen Bai'
        ]
      },
      {
        'CTKD 5': [
          'Bac Giang',
          'Bac Kan',
          'Bac Ninh',
          'Cao Bang',
          'Ha Giang',
          'Tuyen Quang',
          'Hai Duong',
          'Hai Phong',
          'Hung Yen',
          'Lang Son',
          'Quang Ninh',
          'Thai Binh',
          'Thai Nguyen'
        ]
      },
      {
        'CTKD 6': ['Ha Tinh', 'Nghe An', 'Quang Binh', 'Thanh Hoa']
      },
      {
        'CTKD 7': ['Dak Lak', 'Dak Nong', 'Gia Lai', 'Khanh Hoa', 'Kon Tum', 'Phu Yen']
      },
      {
        'CTKD 8': [
          'Ba Ria - Vung Tau',
          'Binh Duong',
          'Binh Phuoc',
          'Binh Thuan',
          'Dong Nai',
          'Lam Dong',
          'Long An',
          'Ninh Thuan',
          'Tay Ninh'
        ]
      },
      {
        'CTKD 9': [
          'An Giang',
          'Bac Lieu',
          'Ben Tre',
          'Ca Mau',
          'Can Tho',
          'Dong Thap',
          'Hau Giang',
          'Kien Giang',
          'Soc Trang',
          'Tien Giang',
          'Tra Vinh',
          'Vinh Long'
        ]
      }
    ]
  }
];
