import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { actionTypes } from './authAction';

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  listPathAllow: [],
};

export const reducer = persistReducer(
  { storage, key: 'user_token', whitelist: ['user', 'authToken','listPathAllow'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const {
          user: { access_token },
          user
        } = action.payload;

        return { access_token, user: user };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      // case actionTypes.UserLoaded: {
      //   const { user } = action.payload;
      //   return { ...state, user };
      // }

      default:
        return state;
    }
  }
);
