import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import SVG from 'react-inlinesvg';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../_helpers';
import { Icon } from '@blueprintjs/core';
import { useLocation } from 'react-router-dom';
import Highcharts from 'highcharts/highstock';
import { useMediaQuery } from 'react-responsive';
import MenuDrawer from '../menu-drawer/MenuDrawer';
import { isMobile } from 'react-device-detect';

export function Brand() {
  const uiService = useHtmlClassService();
  const { pathname } = useLocation();
  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        'aside.self.minimize.toggle'
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1063px)'
  });

  const haveAsideList = [
    '/vienthong/baocaongay',
    '/cntt/baocaongay',
    '/cntt/chitiets/hethongcloud',
    '/cntt/chitiets/kpihoatdonghethong',
    '/qlcl/quychuankythuatquocgia',
    '/cntt/chitiets/kpilopungdung',
    '/cntt/chitiets/kpinode',
  ]

  const collapseMenu = () => {
    Array.prototype.forEach.call(document.getElementsByClassName('aside-dft'), function(e) {
      e.classList.toggle('d-none');
    });
    Array.prototype.forEach.call(document.getElementsByClassName('brand-change'), function(e) {
      e.classList.toggle('col-10');
      e.classList.toggle('col-12');
    });
    Highcharts.charts.forEach((chart) => chart?.reflow());
  };
  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          <img
            alt="logo"
            width="140"
            src={toAbsoluteUrl('/media/logos/smartf.png')}
          />
        </Link>
        {/* end::Logo */}

        {(isMobile || isTabletOrMobileDevice) && !haveAsideList.includes(pathname) && (
          <MenuDrawer />
        )}
        {!isTabletOrMobileDevice && !isMobile && (
          <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
            <span className="svg-icon svg-icon-xl">
              {(!haveAsideList.includes(pathname)) && (
                <Icon
                  icon={`menu`}
                  style={{ color: '#e6d7d7', marginLeft: '25%' }}
                  onClick={() => {
                    collapseMenu();
                  }}
                />
              )}
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/> */}
            </span>
          </button>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
