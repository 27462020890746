import axios from 'axios';
import qs from 'querystring';
import { urlAuth } from 'app/configs';

export const LOGIN_URL = urlAuth;


const instance = axios.create();

export function login(username, password) {
  var params = {
    grant_type: 'password',
    username: 'admin',
    password: '',
    client_id: 'SmartF_App',
    scope: 'offline_access SmartF',
  };
  params.username = username;
  params.password = password;
  return instance.post(LOGIN_URL, qs.stringify(params));
  // return axios.post(LOGIN_URL, { email, password });
}
