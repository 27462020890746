import React from 'react';

import { Drawer } from '@blueprintjs/core';
import { useLocation } from 'react-router-dom';
import MobileBCTQ from './VienThong/BaoCaoTongQuan/AsideMenu/MobileBCTQ';
import AsideMenuURL from 'app/Components/aside-url-one-lv/AsideMenuURL';
import Aside from './VienThong/BaoCaoChiTiet/AsideMenu/Aside';
import AsideDashboard from './DashboardChiTiet/AsideMenu/Aside';
import AsideTopology from './VienThong/Topology/AsideMenu/Aside';

export const AsideMenuMobile = ({ isOpen, setMenuOpen }) => {
  const { pathname } = useLocation();
  const pathLink = pathname.split('/')[1];
  const menuDataBDS = [
    { Name: 'Vô Tuyến', url: '/vienthong/bandoso/votuyen' },
    { Name: 'Truyền dẫn Logic', url: '/vienthong/bandoso/truyendanlogic' },
    { Name: 'Truyền dẫn Cáp', url: '/vienthong/bandoso/truyendancap' },
    { Name: 'Phân lớp', url: '/vienthong/bandoso/PhanLop' },
    { Name: 'Gói cước', url: '/vienthong/bandoso/GoiCuoc'},
    { Name: 'Lưu lượng', url: '/vienthong/bandoso/LuuLuong'},
    { Name: 'Sẵn sàng mạng lưới', url: '/vienthong/bandoso/sansang'},
    { Name: 'Handset', url:'/vienthong/bandoso/handset'},
    { Name: 'Vùng phủ sóng mô phỏng', url:'/vienthong/bandoso/vungphusong'}
  ];
  const menuDataTG = [
    { Name: 'Mô tả KQI-KPI', url: '/trogiup/motakqikpi' },
    { Name: 'Công thức KPI, KQI', url: '/trogiup/congthuckqikpi' },
    { Name: 'Hướng dẫn truy cập', url: '/trogiup/help' },
    { Name: 'Hướng dẫn sử dụng', url: '/trogiup/document' },
    // { Name: 'Log Truy Cập', url: '/trogiup/logtruycap' },
    { Name: 'Danh sách tọa độ Node truy cập', url: '/trogiup/toadonode' }
  ];
  const AsideMenu = () => {
    if (pathname.includes('vienthong/topology')){
      return <AsideTopology isMobile setMenuOpen={setMenuOpen}></AsideTopology>;
    }
    if (pathname.includes('dashboardchitiet')){
      return <AsideDashboard isMobile setMenuOpen={setMenuOpen}></AsideDashboard>;
    }
    if (pathname.includes('vienthong/chitiet')){
      return <Aside isMobile setMenuOpen={setMenuOpen}></Aside>;
    }
    if (pathname.includes('vienthong/tongquan')){
      return <MobileBCTQ setMenuOpen={setMenuOpen}></MobileBCTQ>;
    }
    if (pathname.includes('bandoso')){
      return <AsideMenuURL menulist={menuDataBDS} fnClick={setMenuOpen} />;
    }
    if (pathname.includes('trogiup')){
      return <AsideMenuURL menulist={menuDataTG} fnClick={setMenuOpen} />;
    }
    return <MobileBCTQ setMenuOpen={setMenuOpen}></MobileBCTQ>;
  };

  return (
    <Drawer
      isOpen={isOpen}
      icon="menu"
      onClose={() => setMenuOpen(false)}
      title="Menu"
      lazy
      usePortal
      backdropClassName="sk-fade-in sk-spinner line-scale"
      hasBackdrop
      transitionDuration={100}
      position="left"
      style={{ overflowY: 'scroll' }}
    >
      <AsideMenu />
    </Drawer>
  );
};

export default AsideMenuMobile;
