import { UPDATE_ROUTER_LEVEL } from './baoCaoTongQuanAction';

const initialState = {
  menulevel: {
    menulv1: 'TTML',
    menulv2: null,
    menulv3: null,
    menulv4: null
  }
};

const updateRouterLevel = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ROUTER_LEVEL:
      return {
        ...state.menulevel,
        menulevel: action.payload.router
      };
    default:
      return state;
  }
};

export default updateRouterLevel;
